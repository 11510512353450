import api from 'api';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import TableButton from 'components/commons/table/TableButton';
import PeopleInfoCell from 'components/app/people/PeopleInfoCell';
import { PERSON_ROLE_LABEL } from 'constants/app/people';
import { PersonCmpData } from 'models/people';
import { TableColumnType } from 'models/table';
import settings from 'settings';

function PeopleTablePage() {
  const impersonate = (id: string) => {
    api.people.impersonate(id).then((res) => {
      window.open(res.impersonation_link, '_blank');
    });
  };
  const sendTestPushNotification = (id: string) => {
    api.people.sendTestPushNotification(id).then((res) => {
      console.log(`Notification sent to ${id}`)
    });
  };

  const tableColumns: TableColumnType<PersonCmpData>[] = [
    {
      title: 'Person',
      render: (row) => <PeopleInfoCell tableRow={row} />,
    },
    {
      title: 'Email',
      render: (row) => row.email_address,
    },
    {
      title: 'Role',
      render: (row) => PERSON_ROLE_LABEL[row.role],
    },
    {
      title: '',
      render: (row) => <>
        <TableButton tooltipText="Impersonate" iconClass="bi bi-eye-fill" onClick={() => impersonate(row.id)} />
        {settings.showTestPushNotification && <TableButton tooltipText="Send test push notification" iconClass="bi bi-bell-fill" onClick={() => sendTestPushNotification(row.id)} />}
      </>,
    },
  ];

  return <ServerSideTable<PersonCmpData> search={true} columns={tableColumns} fetchApi={api.people.get}></ServerSideTable>;
}

export default PeopleTablePage;
