import { FILE_TYPE } from 'constants/utilities/devTools';
import { useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import ActionButton from './buttons/ActionButton';

interface DropZoneProps {
  onChange: (files: any[]) => any;
  acceptedFileTypes: FILE_TYPE[];
  isDisabled?: boolean;
  maxFilesPerDrop?: number;
  maxFilesTotalCount?: number;
  filesName?: string;
}

function DropZone({ onChange, acceptedFileTypes, isDisabled = false, maxFilesPerDrop = 1, maxFilesTotalCount = 1, filesName = 'files' }: DropZoneProps) {
  const [droppedFiles, setDroppedFiles] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  const onDropAccepted = (files: any[]) => {
    if (files.length > maxFilesTotalCount - droppedFiles.length) return;

    files.forEach((file) => {
      droppedFiles.push(file);
    });
    setDroppedFiles([...droppedFiles]);
    onChange(droppedFiles);
  };

  const emptyButtonClickHandler = () => {
    setDroppedFiles([]);
    onChange([]);
  };

  const removeButtonClickHandler = (fileName: string) => {
    const fileToDelete = droppedFiles.find((f) => f.name == fileName);
    const index = droppedFiles.indexOf(fileToDelete);
    if (index !== -1) {
      droppedFiles.splice(index, 1);
      setDroppedFiles([...droppedFiles]);
      onChange(droppedFiles);
    }
  };

  const onDragOverHandler = (e: any) => {
    if (maxFilesNumberReached || isDragReject) {
      e.dataTransfer.dropEffect = 'none';
    } else {
      e.dataTransfer.dropEffect = 'copy';
    }
    e.preventDefault();
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDropAccepted,
    accept: { acceptedFileTypes } as Accept,
    disabled,
    maxFiles: maxFilesPerDrop,
    onDragOver: onDragOverHandler,
  });

  const maxFilesNumberReached = droppedFiles.length == maxFilesTotalCount;

  return (
    <>
      <div className="clear-files-btn">
        <ActionButton label="clear uploaded files list" lightStyle={true} onClick={droppedFiles.length > 0 ? emptyButtonClickHandler : () => undefined} />
      </div>
      <div
        className={`drop-zone ${
          isDragActive ? (maxFilesNumberReached || isDragReject ? 'invalid-drag' : isDragAccept && !maxFilesNumberReached ? 'valid-drag' : '') : ''
        }`}
        {...getRootProps()}
      >
        <input className="dropzone-input" {...getInputProps()} />
        <div className="text-center">
          {maxFilesNumberReached
            ? 'You have reached the maximum upload limit!'
            : isDragActive
            ? isDragAccept
              ? `Release to drop the ${filesName} here`
              : `It is only possible to drop a maximum of ${maxFilesPerDrop} ${filesName} at time, for a total of ${maxFilesTotalCount} files`
            : `You have uploaded ${droppedFiles.length} ${filesName}. Drag and drop or click to open the explorer`}
        </div>
      </div>
      {droppedFiles.map((f, i) => {
        return (
          <div key={i} className="drop-zone-file-container">
            <div>
              <i className={`bi bi-filetype-${(f.name as string).split('.').at(length - 1)}`} style={{ marginRight: '5px' }}></i>
              {f.name}
              <ul>
                <li>Type: {f.type}</li>
                <li>Size: {f.size + ' bytes'}</li>
              </ul>
            </div>
            <div className="remove-file-btn-container">
              <button className="bi bi-x remove-file-btn" onClick={() => removeButtonClickHandler(f.name)} />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default DropZone;
