import api from 'api';
import { useEffect, useState } from 'react';
import urlUtils from 'utilities/urlUtils';
import { useLocation } from 'react-router-dom';
import { JobImportType } from 'models/jobs';
import JobImportDetailsCard from 'components/job/JobImportDetailsCard';

function JobImportsDetailPage() {
  const location = useLocation();
  const id = location.pathname.replace(urlUtils.jobs.imports.main() + '/', '');
  const [importInfo, setImportInfo] = useState<JobImportType>();

  useEffect(() => {
    api.jobs.getImportDetails(id).then((res) => {
      setImportInfo(res);
    });
  }, []);

  // TODO: mettere loader
  return <JobImportDetailsCard importInfo={importInfo} id={id} />;
}

export default JobImportsDetailPage;
