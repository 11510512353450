import { useEffect, useState } from 'react';
import { getJwtToken } from 'context/authContext';
import Card from 'components/commons/Card';
import settings from 'settings';
import { Button } from 'react-bootstrap';

function HangfireCard() {
  const [tkn, setTkn] = useState<unknown>();

  useEffect(() => {
    getJwtToken().then((t) => setTkn(t));
  }, []);

  if (tkn == null) {
    return null;
  }
  return (
    <Card title="Hangfire">
      <div style={{ display: 'flex', flexDirection: 'row-reverse', height: '2.5rem' }}>
        <Button onClick={() => window.open(`${settings.baseUrl}/hangfire?jwt_token=${tkn}`)}>Open in another tab</Button>
      </div>
      <div className="hangfire-iframe-container">
        <iframe id="hangfire-iframe" title="hangfire-job" src={`${settings.baseUrl}/hangfire?jwt_token=${tkn}`} width="100%" height="100%" />
      </div>
    </Card>
  );
}

export default HangfireCard;
