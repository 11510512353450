import React, { createContext, Dispatch, useReducer, useState } from 'react';
import { generateContext } from './baseContext';

type InitialStateType = {
  currentRoute: any;
};

const initialState = {
  currentRoute: null
} as InitialStateType;

const ACTIONS = {
  SET_CURRENT_ROUTE_DATA: 'set_current_route_data'
};

function reduceFunction(oldState: any, action: any) {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_ROUTE_DATA: {
      const currentRoute = action.value;
      const newState = {
        currentRoute
      };
      return newState;
    }
    default: {
      throw new Error();
    }
  }
}

export default {
  ...generateContext<InitialStateType>(initialState, reduceFunction),
  ACTIONS
};
