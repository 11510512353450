// import { useContext, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import urlUtils from 'utilities/urlUtils';
// import AuthContext from '../context/authContext';
// import settings from 'settings';

/* MADE BY ME*/
import Card from '../components/login/LoginCard';

function LoginPage() {
  // const navigate = useNavigate();
  // const [email, setEmail] = useState(settings.loginEmail ?? '');
  // const [password, setPassword] = useState(settings.loginPassword ?? '');
  // const { state, dispatch } = useContext(AuthContext.store);

  // console.log('settings', settings);

  return (
    // <Form>
    //   <Form.Group className="mb-3" controlId="formBasicEmail">
    //     <Form.Label>Email address</Form.Label>
    //     <Form.Control type="email" placeholder="Enter email" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
    //     <Form.Text className="text-muted">We&apos;ll never share your email with anyone else.</Form.Text>
    //   </Form.Group>

    //   <Form.Group className="mb-3" controlId="formBasicPassword">
    //     <Form.Label>Password</Form.Label>
    //     <Form.Control type="password" placeholder="Password" defaultValue={password} onChange={(e) => setPassword(e.target.value)} />
    //   </Form.Group>
    //   <Form.Group className="mb-3" controlId="formBasicCheckbox">
    //     <Form.Check type="checkbox" label="Check me out" />
    //   </Form.Group>
    //   <Button
    //     variant="primary"
    //     onClick={(values) => {
    //       api.auth.login(email, password).then((jwtToken) => {
    //         dispatch({
    //           type: AuthContext.ACTIONS.SET_FROM_TOKEN,
    //           value: jwtToken,
    //         });
    //         navigate(urlUtils.main(), { replace: true });
    //       });
    //     }}
    //   >
    //     Submit
    //   </Button>
    // </Form>

    <div className="card-container">
      <Card />
    </div>
  );
}

export default LoginPage;
