import React from 'react';

interface IconProps {
  name: string;
  surname: string;
  color: string;
  photo: any;
}

function PeopleIcon({ name, surname, color, photo }: IconProps) {
  const initials = name.charAt(0) + surname.charAt(0);

  return (
    <div className="people-icon-main-container" style={{ backgroundColor: color }}>
      {photo != null ? <img src={photo} /> : <div className="people-icon-initials">{initials}</div>}
    </div>
  );
}

export default PeopleIcon;
