import client from 'api/client';
import { ServerResponse } from 'http';
import { PendingMigrationsResData } from 'models/utilities';

export function getPendingMigrations(): Promise<PendingMigrationsResData> {
  return client.get(`backoffice/utilities/migrations`);
}

export function postUpdateDatabase(): Promise<any> {
  return client.post(`backoffice/utilities/align-db`);
}
