import React, { ReactNode, useContext } from 'react';
import AuthContext, { removeJwtToken } from 'context/authContext';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import api from 'api';
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { ReactComponent as LogoImage } from 'assets/images/logo_notifyme_text.svg';

function Header() {
  const navigate = useNavigate();
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext.store);

  const onLogoutButtonClick = () => {
    api.auth.logout().then(() => {
      authDispatch({ type: AuthContext.ACTIONS.CLEAR_USER });
      navigate(urlUtils.login(), { replace: true });
    });
  };

  console.log(authState.user);

  return (
    <Navbar>
      <Container fluid>
        <Navbar.Brand href={urlUtils.main()}>
          <LogoImage />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <NavDropdown align="end" title={authState.user.name} id="collasible-nav-dropdown">
              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider /> */}
              <NavDropdown.ItemText>
                <Button variant="danger" onClick={() => onLogoutButtonClick()} style={{ width: '100%' }}>
                  Logout
                </Button>
              </NavDropdown.ItemText>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
