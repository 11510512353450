import api from 'api';
import Card from 'components/commons/Card';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import { JobImportLineType, JobImportType } from 'models/jobs';
import { TableColumnType } from 'models/table';

interface PropsType {
  importInfo: JobImportType | undefined;
  id: string;
}

function JobImportDetailsCard({ importInfo, id }: PropsType) {
  const tableColumns: TableColumnType<JobImportLineType>[] = [
    {
      title: 'Converted',
      render: (row) => (row.converted ? 'Yes' : 'No'),
    },
    {
      title: 'Data',
      render: (row) => row.raw_data,
    },
  ];

  return (
    <Card title={`Job import "${importInfo?.job_id}" info`}>
      <div>
        {importInfo?.status && (
          <div>
            <h4 style={{ marginTop: '2rem' }}>
              <strong>Status</strong>
            </h4>
            <p>{importInfo?.status}</p>
          </div>
        )}
      </div>
      <div>
        <h4 style={{ marginBottom: '1.5rem', marginTop: '2rem' }}>
          <strong>Creator Information</strong>
        </h4>
        {importInfo?.creator?.first_name && (
          <div>
            <h6>
              <strong>First Name</strong>
            </h6>
            <p>{importInfo?.creator?.first_name}</p>
          </div>
        )}
        {importInfo?.creator?.family_name && (
          <div>
            <h6>
              <strong>Family Name</strong>
            </h6>
            <p>{importInfo?.creator?.family_name}</p>
          </div>
        )}
        {importInfo?.creator?.email_address && (
          <div>
            <h6>
              <strong>Email Address</strong>
            </h6>
            <p>{importInfo.creator?.email_address}</p>
          </div>
        )}
        <h4 style={{ marginTop: '3rem' }}>
          <strong>Import Lines</strong>
        </h4>
        <ServerSideTable<JobImportLineType> search={true} columns={tableColumns} fetchApi={api.jobs.getImportLinesList} fetchApiData={id} />
      </div>
    </Card>
  );
}

export default JobImportDetailsCard;
