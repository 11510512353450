
import { ImportTemplatePackageSettingsType } from 'models/importTemplates';
import PackageFieldSelect from './inputs/PackageFieldSelect';
import { FormCheck } from 'react-bootstrap';

type Props = {
  data: ImportTemplatePackageSettingsType;
  setFormData: (name: string, value: any) => void
}

export default function ImportTemplatePackageSettings({ data, setFormData }: Props) {
  return <>
  <FormCheck checked={data.avoid_duplicates} type="checkbox" label="Avoid duplicates" onChange={(e: any) => setFormData('avoid_duplicates', e.target.checked)} />
  <PackageFieldSelect value={data.package_identifier_field} onChange={(newVal) => setFormData('package_identifier_field', newVal)} />
  </>;
}