import DropZone from 'components/commons/DropZone';
import { FILE_TYPE } from 'constants/utilities/devTools';
import { ProcessFamilyData, WorkflowImportData } from 'models/utilities';
import FormDropdown from 'components/commons/form/FormDropdown';
import FormInput from 'components/commons/form/FormInput';
import { PROCESS_TYPE_LABEL, PROCESS_TYPE } from 'constants/app/accounts';
import { HtmlHTMLAttributes, useEffect, useState } from 'react';
import { Dropdown, DropdownButton, FormCheck } from 'react-bootstrap';
import { getValueLabel } from 'utilities/functionUtils';
import Form from 'components/commons/form/Form';
import api from 'api';
import { INPUT_TYPE } from 'constants/inputType';

interface ProcessFamilyProps {
  onFamilyChange: (event: any) => any;
  onNewFamilyChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  existingFamilies: ProcessFamilyData[];
  selectedFamily: string;
  onNewChange: (value: boolean) => any;
}

function FormProcessFamily({ onFamilyChange, onNewFamilyChange, existingFamilies, selectedFamily, onNewChange }: ProcessFamilyProps) {
  const [newFamily, setNewFamily] = useState<boolean>(false);

  const newCheckChangeHandler = (value: boolean) => {
    setNewFamily(value);
    onNewChange(value);
  };

  const dropdownItems: any = [];
  existingFamilies.forEach((item) => {
    dropdownItems.push(<Dropdown.Item eventKey={item.name}>{item.name}</Dropdown.Item>);
  });

  return (
    <div className="form-label-main-container">
      <div className="form-label-and-checkbox" style={{ fontSize: '1rem' }}>
        <p style={{ marginBottom: '0.5rem' }}>Process Family</p>
        <FormCheck checked={newFamily} type="checkbox" label="New" onChange={(e) => newCheckChangeHandler(e.target.checked)} />
      </div>
      {newFamily ? (
        <input onChange={onNewFamilyChange} style={{ height: '2.2rem' }} />
      ) : (
        <Dropdown style={{ marginBottom: '0' }}>
          <DropdownButton title={selectedFamily} onSelect={onFamilyChange}>
            {dropdownItems}
          </DropdownButton>
        </Dropdown>
      )}
    </div>
  );
}

interface WorkFlowImportFormProps {
  onChange: (importData: WorkflowImportData) => any;
}

function WorkflowImportForm({ onChange }: WorkFlowImportFormProps) {
  const [familiesVersionsData, setFamiliesVersionsData] = useState<ProcessFamilyData[]>([]);
  const [newProcessFamily, setNewProcessFamily] = useState<boolean>(false);
  const [minVersionValue, setMinVersionValue] = useState<number>(1);
  const [importData, setImportData] = useState<WorkflowImportData>({
    file: null,
    type: PROCESS_TYPE.Undefined,
    family: '',
    version: 1,
    enabled: true,
  });

  useEffect(() => {
    api.utilities.devTools.getFamiliesVersions().then((res) => setFamiliesVersionsData(res));
  }, []);

  useEffect(() => {
    onChange(importData);
  }, [importData]);

  const dropdownChangeHandler = (name: string, event: any) => {
    setImportData((prevData) => ({ ...prevData, [name]: event }));

    if (name == 'family') {
      findMinVersionValue(event);
    }
  };
  const inputChangeHandler = (name: string, event: any) => {
    setImportData((prevData) => ({ ...prevData, [name]: event.target.value }));

    if (name == 'family') {
      findMinVersionValue(event.target.value);
    }
  };
  const checkboxDataChangeHandler = (name: string, event: any) => {
    setImportData((prevData) => ({ ...prevData, [name]: event.target.checked }));
  };
  const droppedFileChangeHandler = (files: any[]) => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function () {
        setImportData((prevData) => ({ ...prevData, file: reader.result }));
      };
      reader.onerror = function () {
        setImportData((prevData) => ({ ...prevData, file: 'error' }));
      };
    }
  };

  const getExistingFamilyVersion = (familyName: string): number => {
    let version = 1;
    familiesVersionsData.forEach((element) => {
      if (element.name == familyName) {
        version = element.version;
      }
    });
    return version;
  };

  const findMinVersionValue = (familyName: string) => {
    if (newProcessFamily) {
      setMinVersionValue(1);
      setImportData((prevData) => ({ ...prevData, version: 1 }));
    } else {
      const version = getExistingFamilyVersion(familyName) as number;
      setMinVersionValue(version);
      setImportData((prevData) => ({ ...prevData, version: version }));
    }
  };

  const dropdownItems = [];
  for (const item in PROCESS_TYPE_LABEL) {
    dropdownItems.push(<Dropdown.Item eventKey={item}>{getValueLabel(item, PROCESS_TYPE_LABEL)}</Dropdown.Item>);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '25px', marginBottom: '50px' }}>
      <div style={{ width: '40%', marginRight: '10%' }}>
        <Form>
          <FormDropdown
            label="Process Type"
            defaultValue={PROCESS_TYPE_LABEL[importData.type ?? PROCESS_TYPE.Undefined]}
            onSelect={(e) => dropdownChangeHandler('type', e)}
          >
            {dropdownItems}
          </FormDropdown>
          <FormProcessFamily
            onFamilyChange={(e) => dropdownChangeHandler('family', e)}
            onNewFamilyChange={(e) => inputChangeHandler('family', e)}
            onNewChange={(value) => {
              setNewProcessFamily(value);
              setImportData((prevData) => ({ ...prevData, family: '' }));
            }}
            existingFamilies={familiesVersionsData}
            selectedFamily={importData.family}
          />
          <FormInput
            type={INPUT_TYPE.Number}
            label="Version"
            value={importData.version}
            min={minVersionValue}
            onChange={(e) => inputChangeHandler('version', e)}
          />
          <FormCheck checked={importData.enabled} type="checkbox" label="Enabled" onChange={(e) => checkboxDataChangeHandler('enabled', e)} />
        </Form>
      </div>
      <div style={{ width: '50%' }}>
        <DropZone maxFilesPerDrop={1} maxFilesTotalCount={1} onChange={droppedFileChangeHandler} acceptedFileTypes={[FILE_TYPE.Zip, FILE_TYPE.XZip]} />
      </div>
    </div>
  );
}

export default WorkflowImportForm;
