import React, { ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import Pagination from './Pagination';

interface FooterProps {
  limit: number;
  offset: number;
  totalCount: number;
  onPageChanged: (limit: number, offset: number) => any;
}

function TableFooter({ limit, offset, totalCount, onPageChanged }: FooterProps) {
  const onLimitValueSelected = (limit: number) => {
    onPageChanged(limit, 0);
  };

  return (
    <div className="table-footer-main-container">
      <div className="paginating-controls-rpp-container">
        <p>Rows per page</p>
        <Dropdown>
          <Dropdown.Toggle>{limit}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onLimitValueSelected(2)}>2</Dropdown.Item>
            <Dropdown.Item onClick={() => onLimitValueSelected(5)}>5</Dropdown.Item>
            <Dropdown.Item onClick={() => onLimitValueSelected(10)}>10</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Pagination limit={limit} offset={offset} totalCount={totalCount} onPageChanged={onPageChanged} />
    </div>
  );
}

export default TableFooter;
