import React, { ReactNode } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  children?: ReactNode;
  disabled?: boolean;
  defaultValue?: any;
  onSelect?: (key: any) => any;
}

function FormDropdown({ label, children, disabled = false, defaultValue, onSelect = undefined }: DropdownProps) {
  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <Dropdown>
        <DropdownButton title={defaultValue} onSelect={onSelect} disabled={disabled}>
          {children}
        </DropdownButton>
      </Dropdown>
    </Form.Group>
  );
}

export default FormDropdown;
