import { ImportTemplateTypeEnum } from "models/importTemplates";

export const IMPORT_TEMPLATE_TYPE_LABEL: Record<ImportTemplateTypeEnum, string> = {
  [ImportTemplateTypeEnum.NONE]: '',
  [ImportTemplateTypeEnum.PROJECT]: 'Project',
  [ImportTemplateTypeEnum.PACKAGE_DOC]: 'Package Doc',
  [ImportTemplateTypeEnum.PACKAGE_EXP]: 'Package Exp',
  [ImportTemplateTypeEnum.PACKAGE_LAB]: 'Package Lab',
  [ImportTemplateTypeEnum.PACKAGE_NOI]: 'Package Noi',
  [ImportTemplateTypeEnum.PACKAGE_TPI]: 'Package Tpi',
  [ImportTemplateTypeEnum.ITEM]: 'Item',
  [ImportTemplateTypeEnum.STEP]: 'Step',
  [ImportTemplateTypeEnum.ITEM_MULTIPACKAGE]: 'Item Multipackage',
  [ImportTemplateTypeEnum.STEP_MULTIPACKAGE]: 'Step Multipackage',
};
