import ActionButton from 'components/commons/buttons/ActionButton';
import Form from 'components/commons/form/Form';
import FormInput from 'components/commons/form/FormInput';
import { useEffect, useState } from 'react';
import CompanyApiKeyComponent from './CompanyApiKeyComponent';
import { CompanyData, CompanyUpdateData } from 'models/companies';
import api from 'api';
import { ACTION_STATE } from 'constants/actionState';

interface CompanyFormProps {
  id: string;
  companyInfo: CompanyData;
  onChange: (data: CompanyData) => any;
}

function CompanyUpdateForm({ id, companyInfo, onChange }: CompanyFormProps) {
  const [apiState, SetApiState] = useState(ACTION_STATE.Idle);

  useEffect(() => {
    api.companies.getCompanyDetails(id).then((res) => {
      onChange(res);
    });
  }, []);

  const formDataChangeHandler = (name: string, value: any) => {
    const info: any = { ...companyInfo };
    info[name] = value;
    onChange(info);
  };

  const updateButtonClickHandler = () => {
    const updateData: CompanyUpdateData = {
      address: companyInfo?.address ?? '',
      city: companyInfo?.city ?? '',
      country: companyInfo?.country ?? '',
      zip_postal_code: companyInfo?.zip_postal_code ?? '',
      email: companyInfo?.email ?? '',
      phone: companyInfo?.phone ?? '',
    };

    SetApiState(ACTION_STATE.Loading);
    api.companies
      .updateCompany(id, updateData)
      .then(() => {
        SetApiState(ACTION_STATE.Success);
        setTimeout(() => SetApiState(ACTION_STATE.Idle), 2000);
      })
      .catch(() => {
        SetApiState(ACTION_STATE.Error);
        setTimeout(() => SetApiState(ACTION_STATE.Idle), 2000);
      });
  };

  return (
    <>
      <Form>
        <FormInput label="Address" value={companyInfo?.address ?? ''} onChange={(e) => formDataChangeHandler('address', e.target.value)} />
        <FormInput label="City" value={companyInfo?.city ?? ''} onChange={(e) => formDataChangeHandler('city', e.target.value)} />
        <FormInput label="Country" value={companyInfo?.country ?? ''} onChange={(e) => formDataChangeHandler('country', e.target.value)} />
        <FormInput label="Postal code" value={companyInfo?.zip_postal_code ?? ''} onChange={(e) => formDataChangeHandler('zip_postal_code', e.target.value)} />
        <FormInput label="Email" value={companyInfo?.email ?? ''} onChange={(e) => formDataChangeHandler('email', e.target.value)} />
        <FormInput label="Phone" value={companyInfo?.phone ?? ''} onChange={(e) => formDataChangeHandler('phone', e.target.value)} />
      </Form>
      <div style={{ marginTop: '0.5rem', marginBottom: '3rem' }}>
        <ActionButton label="update company data" onClick={updateButtonClickHandler} state={apiState} />
      </div>
      <CompanyApiKeyComponent
        apiKey={companyInfo?.public_api_key}
        companyId={id}
        onNewKeyGenerated={(key) => onChange({ ...companyInfo, public_api_key: key })}
      />
    </>
  );
}

export default CompanyUpdateForm;
