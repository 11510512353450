import * as authApi from './auth';
import * as peopleApi from './app/people';
import * as companiesApi from './app/companies';
import * as accountsApi from './app/accounts';
import * as usersApi from './app/users';
import * as hangfireApi from './app/hangfire';
import * as devToolsApi from './utilities/devTools';
import * as databaseApi from './utilities/database';
import * as processFamiliesApi from './processFamilies';
import * as jobsApi from './jobs';
import * as importTemplatesApi from './importTemplates';

export default {
  auth: authApi,
  people: peopleApi,
  companies: companiesApi,
  accounts: accountsApi,
  users: usersApi,
  hangfire: hangfireApi,
  utilities: {
    devTools: devToolsApi,
    database: databaseApi,
  },
  processFamilies: processFamiliesApi,
  jobs: jobsApi,
  importTemplates: importTemplatesApi,
};
