import client from 'api/client';
import { BasePaginatedRequest } from 'models/common';
import {
  ImportPeopleData,
  ImportPeopleResponse,
  ImportWorkflowResponse,
  ProcessFamilyData,
  WorkflowAttributesPaginatedRequest,
  WorkflowImportData,
  WorkflowsPaginatedData,
} from 'models/utilities';

export function getWorkflows(req: WorkflowAttributesPaginatedRequest): Promise<WorkflowsPaginatedData> {
  return client.get(`backoffice/utilities/workflows`, {
    params: req,
  });
}

export function getFamiliesVersions(): Promise<ProcessFamilyData[]> {
  return client.get(`backoffice/utilities/workflows/families-versions`);
}

export function importPeople(req: ImportPeopleData): Promise<ImportPeopleResponse> {
  return client.post(`backoffice/utilities/import-people`, req);
}

export function importWorkflow(req: WorkflowImportData): Promise<ImportWorkflowResponse> {
  return client.post(`backoffice/utilities/import-workflow`, req);
}
