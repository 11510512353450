import Card from 'components/commons/Card';
import DropZone from 'components/commons/DropZone';
import ActionButton from 'components/commons/buttons/ActionButton';
import Form from 'components/commons/form/Form';
import FormDropdown from 'components/commons/form/FormDropdown';
import FormInput from 'components/commons/form/FormInput';
import { PROCESS_TYPE, PROCESS_TYPE_LABEL } from 'constants/app/accounts';
import { FILE_TYPE } from 'constants/utilities/devTools';
import { WorkflowImportData } from 'models/utilities';
import { version } from 'os';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { getValueLabel } from 'utilities/functionUtils';
import WorkflowImportForm from './WorkflowImportForm';
import api from 'api';
import HangfireJobViewer from 'components/job/HangfireJobViewer';

function WorkflowImportCard() {
  const [jobId, setJobId] = useState<string | undefined>();
  const [importData, setImportData] = useState<WorkflowImportData>({
    file: null,
    type: PROCESS_TYPE.Undefined,
    family: '',
    version: 1,
    enabled: true,
  });

  const importButtonClickHandler = () => {
    api.utilities.devTools.importWorkflow(importData).then((res) => setJobId(res.uid));
  };

  return (
    <Card title="Import Workflow">
      <WorkflowImportForm onChange={setImportData} />
      <div style={{ marginTop: '20px', alignSelf: 'center' }}>
        <ActionButton label="import" onClick={importButtonClickHandler} />
      </div>
      {jobId != null ? <HangfireJobViewer jobId={jobId} /> : null}
    </Card>
  );
}

export default WorkflowImportCard;
