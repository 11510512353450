import { PROCESS_TYPE } from 'constants/app/accounts';

export enum FILE_TYPE {
  PlainText = 'text/plain',
  Csv = 'text/csv',
  Json = 'application/json',
  Excel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Zip = 'application/zip',
  XZip = 'application/x-zip-compressed',
}

export enum IMPORT_FILE_TEMPLATE {
  OLD_NME = 1,
  OSS = 2,
}

export const IMPORT_FILE_TEMPLATE_LABEL = {
  [IMPORT_FILE_TEMPLATE.OLD_NME]: 'OLD-NME',
  [IMPORT_FILE_TEMPLATE.OSS]: 'OSS',
};
