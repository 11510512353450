import React, { ReactNode } from 'react';
import { Modal as BSModal } from 'react-bootstrap';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  show: boolean;
  title: string;
  onHide?: () => any;
  hasCloseButton?: boolean;
}

function Modal({ children, show, title, onHide = () => undefined, hasCloseButton = true }: ModalProps) {
  return (
    <BSModal show={show} onHide={onHide}>
      <BSModal.Header closeButton={hasCloseButton}>
        <BSModal.Title>{title}</BSModal.Title>
      </BSModal.Header>
      <BSModal.Body>{children}</BSModal.Body>
    </BSModal>
  );
}

export default Modal;
