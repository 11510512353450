export function getValueLabel(value: any | undefined, labelObj: any) {
  if (value == null) {
    return '-';
  }
  const found = labelObj[value];
  if (found != null) {
    return found;
  }
  return value;
}

export function getDateFromString(strDt: Date | string) {
	if (strDt instanceof Date) {
		return new Date(strDt);
	}
	/* const dt = strDt.substring(0, 19);
	const tz = strDt.substring(19);

	const [date, time] = dt.split(' ');
	const [year, month, day] = date.split('-'); */

  const [date, time] = strDt.split('T');
  const [year, month, day] = date.split('-');
  const [hour, minute, seconds] = time.replace(/\.\d+$/, '').split(':')

	/* let hour = 0,
		minute = 0,
		seconds = 0;

	if (time != null && time != '') {
		const arrayTime = time.split(':');
		hour = +arrayTime[0];
		minute = +arrayTime[1];
		seconds = +arrayTime[2];
	} */

	const convDt = new Date(+year, +month - 1, +day, +hour, +minute, +seconds);

	/* if (time != null && time != '') {
		if (tz != null && tz != '' && tz !== '+00') {
			console.warn('Datetime with timezone ' + tz);
			convDt = addHours(convDt, +tz);
		}
		const tzValue = -convDt.getTimezoneOffset() / 60;
		convDt = addHours(convDt, tzValue);
	} */
	return convDt;
}

function addHours(dt: Date, h: number) {
	dt.setTime(dt.getTime() + h * 60 * 60 * 1000);
	return dt;
}

export function getDateString(dt: string| undefined) {
	if (dt == null) {
		return null;
	}
	const d = getDateFromString(dt);
	return internalGetDateString(d);
}

export function getDateTimeString(dt: string | undefined) {
  console.log("getDateTimeString", dt)
	if (dt == null) {
		return null;
	}
	const d = getDateFromString(dt);
	return internalGetDateString(d) + ' ' + internalGetTimeString(d);
}

export function getTimeString(dt: string| undefined) {
	if (dt == null) {
		return null;
	}
	const d = getDateFromString(dt);
	return internalGetTimeString(d);
}

function internalGetDateString(d: Date| undefined) {
	if (d == null) {
		return null;
	}
	return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
}
function internalGetTimeString(d: Date| undefined) {
	if (d == null) {
		return null;
	}
	let str = d.getHours() + ':';
	if (d.getMinutes() < 10) {
		str += '0';
	}
	str += d.getMinutes();
	return str;
}

const manageTwoChar = (tmp: string) => {
	return (('' + tmp).length < 2 ? '0' : '') + tmp;
};
