import client from 'api/client';
import { BasePaginatedRequest } from 'models/common';
import { ImportTemplate, ImporTemplatesPaginatedData, ImportTemplateDetail, ImportTemplateBody, ImportTemplateGeneralInfo } from 'models/importTemplates';

export function getList(req: BasePaginatedRequest): Promise<ImporTemplatesPaginatedData> {
  return client.get(`backoffice/template/import`, {
    params: req,
  });
}

export function getInfo(base64: string): Promise<ImportTemplateGeneralInfo> {
  return client.post(`backoffice/template/import/get-info`, { base64: base64 });
}

export function get(id: string, getLink: boolean): Promise<ImportTemplateDetail> {
  return client.get(`backoffice/template/import/${id}/${getLink}`);
}

export function addTemplate(tmpl: ImportTemplateBody): Promise<ImportTemplateDetail> {
  return client.post(`backoffice/template/import/`, {
    ...tmpl,
    accountId: tmpl.account?.id
  });
}

export function putTemplate(tmpl: ImportTemplateBody, id: string): Promise<ImportTemplateDetail> {
  const newBody = {
    ...tmpl,
    account_id: tmpl.account?.id
  }
  return client.put(`backoffice/template/import/${id}`, newBody);
}
