/* eslint-disable react/display-name */
import api from 'api';
import ActionButton from 'components/commons/buttons/ActionButton';
import { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';

type PropsType = {
  jobId: string;
};

type LogLineType = {
  time: number;
  timeString: string;
  text: string;
};

enum JobState {
  UNDEFINED = 'undefined',
  PROCESSING = 'Processing',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
  ENQUEUED = 'Enqueued',
  SCHEDULED = 'Scheduled',
  DELETED = 'Deleted',
  AWAITING = 'Awaiting',
}

const JobStateClassDictionary = {
  [JobState.UNDEFINED]: 'hf-base',
  [JobState.PROCESSING]: 'hf-warning',
  [JobState.SUCCEEDED]: 'hf-success',
  [JobState.FAILED]: 'hf-danger',
  [JobState.ENQUEUED]: 'hf-base',
  [JobState.SCHEDULED]: 'hf-base',
  [JobState.DELETED]: 'hf-delete',
  [JobState.AWAITING]: 'hf-base',
};

// https://dev.to/mahdi/authorizing-hangfire-dashboard-in-net-web-api-using-jwt-tokens-1b50
export default ({ jobId }: PropsType) => {
  const [doc, setDoc] = useState<Document>();
  const [intervalID, setIntervalID] = useState<NodeJS.Timer>();

  const [showLogs, setShowLogs] = useState<boolean>(false);
  const [jobState, setJobState] = useState<string | undefined>();
  const [logArray, setLogArray] = useState<LogLineType[]>([]);
  const [checked, setChecked] = useState(true);
  const [scrollbarSwitchDisabled, setScrollbarSwitchDisabled] = useState(true);
  const scrollbar = useRef() as React.MutableRefObject<HTMLDivElement>;

  const onScrollbarLockChange = (e: any) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    setIntervalID(setInterval(getSrc, 1000));

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    if (checked && scrollbar?.current) {
      const scroll = scrollbar?.current?.scrollHeight - scrollbar?.current?.clientHeight;
      if (scroll > 0) {
        scrollbar?.current?.scrollTo(0, scroll);
        setScrollbarSwitchDisabled(false);
      }
    }
  }, [logArray]);

  useEffect(() => {
    if (doc) {
      const lines = Array.from(doc.getElementsByClassName('console-area')[0].getElementsByClassName('line'));
      const convLines = lines.map((l) => {
        const lSpan = l.getElementsByTagName('span')[0];
        const lTimeString = lSpan.innerHTML;
        const lTime = lSpan.getAttribute('data-moment-title') as number | null;
        const lText = l.innerHTML.replace(lSpan.outerHTML, '');
        return {
          timeString: lTimeString,
          text: lText,
          time: lTime,
        } as LogLineType;
      });
      setLogArray(convLines);
      const stateCardTitle = doc.getElementsByClassName('state-card-title')[0];
      const jobDateTime = stateCardTitle.getElementsByTagName('small')[0];
      const state = stateCardTitle.innerHTML.replace(jobDateTime.outerHTML, '');
      setJobState(state?.trim());
    }
  }, [doc]);

  useEffect(() => {
    // MEMO: al momento consideriamo i job attivi solo se sono in stato processing
    if (jobState !== JobState.PROCESSING) {
      clearInterval(intervalID);
    }
  }, [jobState]);

  const getSrc = () => {
    api.hangfire.getHangfireJobsDetails(jobId).then((res) => {
      const parser = new DOMParser(),
        docData = parser.parseFromString(res, 'text/html');
      setDoc(docData);
    });
  };

  if (doc == null) {
    return null;
  }
  return (
    <>
      <div className={`hangfire-state-card ${JobStateClassDictionary[jobState as JobState]}`}>
        <div className="hangfire-state">
          <span className="hangfire-state-text">Job status: {jobState}</span>
          {!showLogs && (
            <div className="hangfire-logs-btn">
              <ActionButton label="Show logs" lightStyle={true} onClick={() => setShowLogs(true)} />
            </div>
          )}
          {showLogs && (
            <div className="hangfire-logs-btn">
              <ActionButton label="Hide logs" lightStyle={true} onClick={() => setShowLogs(false)} />
            </div>
          )}
        </div>
        {showLogs && (
          <Card bg={'dark'} key={'Dark'} text={'white'}>
            <Card.Header>
              <span className="hangfire-state-text">Logs</span>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="scrollbarLock"
                  disabled={scrollbarSwitchDisabled}
                  checked={checked}
                  onChange={(e) => onScrollbarLockChange(e)}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <div className="hangfire-container">
                <div ref={scrollbar} className="hangfire-scroll-container">
                  <div className="hangfire-content">
                    <table>
                      <tbody>
                        {logArray != null &&
                          logArray.map((l, idx) => (
                            <tr key={`row-${idx}-${l.time}`}>
                              <td key={`column-${idx}-${l.time}-timeString`}>{l.timeString}</td>
                              <td key={`column-${idx}-${l.time}-text`}>{l.text}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
};
