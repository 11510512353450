export enum PERSON_ROLE {
  StandardUser = 'standard_user',
  Owner = 'owner',
  Admin = 'admin',
  PowerUser = 'power_user',
}

export const PERSON_ROLE_LABEL = {
  [PERSON_ROLE.StandardUser]: 'Standard User',
  [PERSON_ROLE.Owner]: 'Owner',
  [PERSON_ROLE.Admin]: 'Admin',
  [PERSON_ROLE.PowerUser]: 'Power User',
};
