import { BasePaginatedRequest } from 'models/common';
import client from 'api/client';
import { AccountDetailCmpData, LicenceCmpData, AccountsPaginatedData, NewLicenseData } from 'models/accounts';

export function get(req: BasePaginatedRequest): Promise<AccountsPaginatedData> {
  return client.get(`backoffice/accounts`, {
    params: req,
  });
}

export function getDetail(id: string): Promise<AccountDetailCmpData> {
  return client.get(`backoffice/accounts/${id}`);
}

export function enableProcessFamily(accountId: string, familyId: string): Promise<AccountDetailCmpData> {
  return client.post(`backoffice/accounts/${accountId}/processfamilies/enable/${familyId}`);
}

export function disableProcessFamily(accountId: string, familyId: string): Promise<any> {
  return client.delete(`backoffice/accounts/${accountId}/processfamilies/disable/${familyId}`);
}

export function updateLicense(id: string, license: NewLicenseData): Promise<LicenceCmpData> {
  return client.put(`backoffice/accounts/${id}/license`, license);
}

export function getLicense(id: string): Promise<LicenceCmpData> {
  return client.get(`backoffice/accounts/${id}/license`);
}
