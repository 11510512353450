import React, { Component } from 'react';
import MainMenu from './MainMenu';

type SiderMenuProps = React.HTMLAttributes<HTMLElement>;

const SiderMenu = ({ className, ...rest }: SiderMenuProps) => {
  return (
    <div className="sider-menu">
      <MainMenu />
    </div>
  );
};

export default SiderMenu;
