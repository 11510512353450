import client from 'api/client';

export function login(username: string, password: string): Promise<any> {
  return client
    .post(`backoffice/user/signin`, { name: username, password })
    .then((result: any) => {
      return result.token;
    })
    .catch((err: any) => {
      // Something is Wrong
      console.error('LOGIN ERROR', err);
    });
}

export function logout(): Promise<any> {
  return new Promise((resolve, reject) => {
    resolve(null);
  });
  // return client.post(`backoffice/signout`).then((result: any) => {
  //   return result.token;
  // })
  // .catch((err: any) => {
  //   // Something is Wrong
  //   console.error('LOGIN ERROR', err);
  // });
}
