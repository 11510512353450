/* eslint-disable @typescript-eslint/ban-types */
import { AccountCmpData } from './accounts';
import { BasePaginatedData } from './common';

export enum ImportTemplateTypeEnum {
  NONE = '',
  PROJECT = 'project',
  PACKAGE_DOC = 'package-doc',
  PACKAGE_EXP = 'package-exp',
  PACKAGE_LAB = 'package-lab',
  PACKAGE_NOI = 'package-noi',
  PACKAGE_TPI = 'package-tpi',
  ITEM = 'item',
  STEP = 'step',
  ITEM_MULTIPACKAGE = 'item-multipackage',
  STEP_MULTIPACKAGE = 'step-multipackage',
}

export type ImportTemplate = {
  id: string;
  type: ImportTemplateTypeEnum;
  version?: string;
  name: string;
  //path: string;
  mime_type: string;
};

export type ImportTemplateBody = {
  type: ImportTemplateTypeEnum;
  version?: string;
  name: string;
  //path: string;
  mime_type: string;
  upload_base64?: string;
  file_extension?: string;
  settings: any;
  account?: AccountCmpData;
};

export type ImportTemplateDetail = {
  id: string;
  type: ImportTemplateTypeEnum;
  version?: string;
  name: string;
  //path: string;
  mime_type: string;
  preassigned_url?: string;
  upload_base64?: string;
  file_extension?: string;
  settings: ImportTemplateProjectSettingsType | ImportTemplatePackageSettingsType | ImportTemplateItemSettingsType | ImportTemplateStepSettingsType;
  account?: AccountCmpData;
};

export type ImportTemplateGeneralInfo = {
  type: ImportTemplateTypeEnum;
  type_check: boolean;
  version: string;
  version_check: boolean;
  key: string;
  key_check: boolean;
  has_hidden_worksheet: boolean;
};

export class ImporTemplatesPaginatedData extends BasePaginatedData<ImportTemplate> {}

// -------- IMPORT TEMPLATE SETTINGS TYPES ----------
export type ImportTemplateProjectSettingsType = {
}

export type ImportTemplatePackageSettingsType = {
  avoid_duplicates: boolean;
  package_identifier_field?: string;
}

export type ImportTemplateItemSettingsType = {
}
export type ImportTemplateStepSettingsType = {
}

type ImportTemplateResourceMultiPackageSettingsType = {
  package_identifier_field?: string;
}
export type ImportTemplateItemMultiPackageSettingsType = ImportTemplateResourceMultiPackageSettingsType & {
}
export type ImportTemplateStepMultiPackageSettingsType = ImportTemplateResourceMultiPackageSettingsType & {
}