import { ImportDataSettings } from 'models/utilities';
import React, { useEffect, useState } from 'react';
import Form from 'components/commons/form/Form';
import FormInput from 'components/commons/form/FormInput';
import FormDropdown from 'components/commons/form/FormDropdown';
import { Dropdown, FormCheck } from 'react-bootstrap';
import { IMPORT_FILE_TEMPLATE, IMPORT_FILE_TEMPLATE_LABEL } from 'constants/utilities/devTools';
import { getValueLabel } from 'utilities/functionUtils';

interface SettingsFormProps {
  onChange: (settings: ImportDataSettings) => any;
  defaultSettings: ImportDataSettings;
}

const PeopleImportSettingsForm: React.FC<SettingsFormProps> = ({ onChange, defaultSettings }) => {
  const [settings, setSettings] = useState<ImportDataSettings>(defaultSettings);

  useEffect(() => {
    onChange(settings);
  }, [settings]);

  const handleCheckboxChange = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prevSettings) => ({ ...prevSettings, [name]: event.target.checked }));
  };

  const handleInputChange = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prevSettings) => ({ ...prevSettings, [name]: event.target.value }));
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prevSettings) => ({ ...prevSettings, import_file_template: Number(event) }));
  };

  const dropdownItems = [];
  for (const item in IMPORT_FILE_TEMPLATE_LABEL) {
    dropdownItems.push(<Dropdown.Item eventKey={item}>{getValueLabel(item, IMPORT_FILE_TEMPLATE_LABEL)}</Dropdown.Item>);
  }

  return (
    <Form className="import-settings-form">
      <FormInput label="Forced email prefix:" value={settings.forced_email_prefix} onChange={(e) => handleInputChange('forced_email_prefix', e)} />
      <FormInput
        label="Autogenerated owner default password:"
        value={settings.autogenerated_owner_default_password}
        onChange={(e) => handleInputChange('autogenerated_owner_default_password', e)}
      />
      <FormInput
        label="Autogenerated owner prefix:"
        value={settings.autogenerated_owner_prefix}
        onChange={(e) => handleInputChange('autogenerated_owner_prefix', e)}
      />
      <FormInput
        label="Autogenerated owner domain:"
        value={settings.autogenerated_owner_domain}
        onChange={(e) => handleInputChange('autogenerated_owner_domain', e)}
      />
      <FormInput label="Forced email domain:" value={settings.forced_email_domain} onChange={(e) => handleInputChange('forced_email_domain', e)} />
      <FormCheck
        checked={settings.user_custom_code_for_autogenerated_owner_email}
        type="checkbox"
        label="Use custom code for autogenerated owner email"
        onChange={(e) => handleCheckboxChange('user_custom_code_for_autogenerated_owner_email', e)}
      />
      <FormCheck
        checked={settings.forced_mail_for_test}
        type="checkbox"
        label="Forced email for test"
        onChange={(e) => handleCheckboxChange('forced_mail_for_test', e)}
      />
      <FormCheck
        checked={settings.bu_centric_roles}
        type="checkbox"
        label="Business unit centric roles"
        onChange={(e) => handleCheckboxChange('bu_centric_roles', e)}
      />
      <FormInput
        label="Output file custom suffix:"
        value={settings.output_file_custom_suffix}
        onChange={(e) => handleInputChange('output_file_custom_suffix', e)}
      />
      <FormDropdown label="Import file template" defaultValue={IMPORT_FILE_TEMPLATE_LABEL[settings.import_file_template]} onSelect={handleDropdownChange}>
        {dropdownItems}
      </FormDropdown>
    </Form>
  );
};

export default PeopleImportSettingsForm;
