import api from 'api';
import ActionButton from 'components/commons/buttons/ActionButton';
import Card from 'components/commons/Card';
import MessageBox from 'components/commons/modal/MessageBox';
import { ACTION_STATE } from 'constants/actionState';
import { useEffect, useState } from 'react';

function DatabaseUtilitiesPage() {
  const [pendingMigrations, setPendingMigrations] = useState<number>();
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [updateSucceeded, setUpdateSucceeded] = useState(false);
  const [apiState, SetApiState] = useState(ACTION_STATE.Idle);

  useEffect(() => {
    api.utilities.database.getPendingMigrations().then((res) => setPendingMigrations(res.pending_migrations));
  }, [updateSucceeded]);

  const updateClickHandler = () => {
    setShowMessageBox(false);
    api.utilities.database
      .postUpdateDatabase()
      .then(() => {
        setUpdateSucceeded(true);
        SetApiState(ACTION_STATE.Success);
        setTimeout(() => SetApiState(ACTION_STATE.Idle), 2000);
      })
      .catch(() => {
        setUpdateSucceeded(false);
        SetApiState(ACTION_STATE.Error);
        setTimeout(() => SetApiState(ACTION_STATE.Idle), 2000);
      });
  };

  return (
    <>
      <Card title="Database">
        <div className="database-migration-container">
          <div>Pending migrations: {pendingMigrations == 0 ? 'No pending migrations' : pendingMigrations == undefined ? '. . .' : pendingMigrations}</div>
          {pendingMigrations != undefined && pendingMigrations > 0 && (
            <ActionButton label="update database" onClick={() => setShowMessageBox(true)} state={apiState} />
          )}
        </div>
      </Card>
      <MessageBox show={showMessageBox} title={'Are you sure?'} onYes={updateClickHandler} onCancel={() => setShowMessageBox(false)} yesLabel="Update">
        Updating the database may cause data loss if the operation is not done with awareness
      </MessageBox>
    </>
  );
}

export default DatabaseUtilitiesPage;
