import client from 'api/client';
import { BasePaginatedRequest } from 'models/common';
import { JobImportLineType, JobImportLinesPaginatedData, JobImportType, JobImportsPaginatedData } from 'models/jobs';

export function getImportsList(req: BasePaginatedRequest): Promise<JobImportsPaginatedData> {
  return client.get(`backoffice/job/import`, {
    params: req,
  });
}

export function getImportDetails(id: string): Promise<JobImportType> {
  return client.get(`backoffice/job/import/${id}`);
}

export function getImportLinesList(req: BasePaginatedRequest, id: string): Promise<JobImportLinesPaginatedData> {
  return client.get(`backoffice/job/import/${id}/import-lines`, {
    params: req,
  });
}
