import { Auth } from 'aws-amplify';
import axios from 'axios';
import { getJwtToken } from 'context/authContext';


const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`
  // timeout: 1000
});

client.interceptors.request.use(
  async function (options) {
    const jwtToken = await getJwtToken();
    if (jwtToken != null) {
      options.headers!['authorization'] = `Bearer ${jwtToken}`;
    } else {
      delete options.headers!['authorization'];
    }
    return options;
  },
  function (error) {
    console.log('Request error: ', error);
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  async function (response) {
    // TODO: Gestire errori globali
    return response.data;
  },
  function (error) {
    console.log('Request error: ', error);
    return Promise.reject(error);
  }
);


export default client;
