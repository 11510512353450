import MainLayout from 'components/layout';
import AccountsDetailPage from 'pages/app/accounts/AccountDetailPage';
import AccountsTablePage from 'pages/app/accounts/AccountsTablePage';
import DatabaseUtilitiesPage from 'pages/utilities/database/DatabaseUtilitiesPage';
import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import PeopleTablePage from 'pages/app/people/PeopleTablePage';
import DevToolsPage from 'pages/utilities/devTools/DevToolsPage';
import UsersTablePage from 'pages/app/users/UsersTablePage';
import urlUtils from 'utilities/urlUtils';
import CompaniesTablePage from 'pages/app/companies/CompaniesTablePage';
import CompanyDetailPage from 'pages/app/companies/CompanyDetailPage';
import PeopleImportPage from 'pages/utilities/devTools/PeopleImportPage';
import WorkflowTablePage from 'pages/utilities/devTools/WorkflowTablePage';
import HangfirePage from 'pages/utilities/devTools/HangfirePage';
import WorkflowImportPage from 'pages/utilities/devTools/WorkflowImportPage';
import JobImportsTablePage from 'pages/jobs/JobImportsTablePage';
import JobImportsDetailPage from 'pages/jobs/JobImportDetailsPage';
import ImportTemplateTablePage from 'pages/importTemplates/ImportTemplatePages';
import ImportTemplateDetailPage from 'pages/importTemplates/ImportTemplateDetailPage';

export const unauth: any[] = [
  {
    name: 'login',
    title: 'Login',
    path: urlUtils.login(),
    element: <LoginPage />,
  },
];

export const auth: any[] = [
  {
    name: 'home',
    title: 'Home',
    path: urlUtils.main(),
    element: <HomePage />,
  },
  {
    name: 'home',
    title: 'Home',
    path: '/',
    element: <HomePage />,
  },
  {
    name: 'people',
    title: 'People',
    path: urlUtils.people.main(),
    element: <PeopleTablePage />,
  },
  {
    name: 'companies',
    title: 'Companies',
    path: urlUtils.companies.main(),
    element: <CompaniesTablePage />,
  },
  {
    name: 'companies',
    title: 'Companies',
    path: urlUtils.companies.detail(),
    element: <CompanyDetailPage />,
  },
  {
    name: 'accounts',
    title: 'Accounts',
    path: urlUtils.accounts.main(),
    element: <AccountsTablePage />,
  },
  {
    name: 'account',
    title: 'Account',
    path: urlUtils.accounts.detail(),
    element: <AccountsDetailPage />,
  },
  {
    name: 'users',
    title: 'Users',
    path: urlUtils.users(),
    element: <UsersTablePage />,
  },
  {
    name: 'jobs-imports',
    title: 'Imports',
    path: urlUtils.jobs.imports.main(),
    element: <JobImportsTablePage />,
  },
  {
    name: 'jobs-import-details',
    title: 'Import Details',
    path: urlUtils.jobs.imports.detail(),
    element: <JobImportsDetailPage />,
  },
  {
    name: 'import-templates',
    title: 'Templates',
    path: urlUtils.templates.imports.main(),
    element: <ImportTemplateTablePage />,
  },
  {
    name: 'import-templates-details',
    title: 'Template Details',
    path: urlUtils.templates.imports.detail(),
    element: <ImportTemplateDetailPage />,
  },
  {
    name: 'devtools-hangfire',
    title: 'Hangfire',
    path: urlUtils.utilities.devTools.hangfire(),
    element: <HangfirePage />,
  },
  {
    name: 'devtools',
    title: 'Dev Tools',
    path: urlUtils.utilities.devTools.main(),
    element: <DevToolsPage />,
  },
  {
    name: 'devtools-peopleimport',
    title: 'People import',
    path: urlUtils.utilities.devTools.peopleImport(),
    element: <PeopleImportPage />,
  },
  {
    name: 'devtools-workflow',
    title: 'Workflow',
    path: urlUtils.utilities.devTools.workflow(),
    element: <WorkflowTablePage />,
  },
  {
    name: 'devtools-workflowimport',
    title: 'Workflow import',
    path: urlUtils.utilities.devTools.workflowImport(),
    element: <WorkflowImportPage />,
  },
  {
    name: 'database',
    title: 'Databse',
    path: urlUtils.utilities.database(),
    element: <DatabaseUtilitiesPage />,
  },
];

export const allRoutes: any[] = [...unauth, ...auth];
