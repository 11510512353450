import api from 'api';
import ActionButton from 'components/commons/buttons/ActionButton';
import IconButton from 'components/commons/buttons/IconButton';
import Loader from 'components/commons/modal/Loader';
import { ACTION_STATE } from 'constants/actionState';
import { useEffect, useState } from 'react';

interface ApiKeyProps {
  apiKey: string;
  companyId: string;
  onNewKeyGenerated?: (key: string) => any;
}

function CompanyApiKeyComponent({ apiKey, companyId, onNewKeyGenerated = undefined }: ApiKeyProps) {
  const [apiKeyPreview, SetApiKeyPreview] = useState('');
  const [apiKeyCopied, SetApiKeyCopied] = useState(false);
  const [apiState, SetApiState] = useState(ACTION_STATE.Idle);

  useEffect(() => {
    let preview = '';
    for (let i = 0; i < 10; i++) {
      preview += apiKey != null ? apiKey.charAt(i) : '';
    }
    SetApiKeyPreview(preview);
  }, [apiKey]);

  const generateKeyButtonClickHandler = () => {
    SetApiState(ACTION_STATE.Loading);
    api.companies
      .generateNewKey(companyId)
      .then((res) => {
        onNewKeyGenerated != undefined ? onNewKeyGenerated(res.key) : undefined;
        SetApiState(ACTION_STATE.Success);
        setTimeout(() => SetApiState(ACTION_STATE.Idle), 2000);
      })
      .catch(() => {
        SetApiState(ACTION_STATE.Error);
        setTimeout(() => SetApiState(ACTION_STATE.Idle), 2000);
      });
  };

  return (
    <div className="company-detail-info-subcontainer">
      <div className="company-detail-info-subtitle">Public Api Key</div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '30%', minWidth: '350px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p className="api-key-container">{apiKeyPreview + '...'}</p>
          <IconButton
            tooltipText="Copy to clipboard"
            iconClass={`bi bi-clipboard${apiKeyCopied ? '-check' : ''}`}
            onClick={() => {
              navigator.clipboard.writeText(apiKey);
              SetApiKeyCopied(true);
            }}
          />
        </div>
        <ActionButton label="generate new key" onClick={generateKeyButtonClickHandler} state={apiState} />
      </div>
    </div>
  );
}

export default CompanyApiKeyComponent;
