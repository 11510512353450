import api from 'api';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import { TableColumnType } from 'models/table';
import { JobImportType } from 'models/jobs';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import { ImportTemplate } from 'models/importTemplates';
import ActionButton from 'components/commons/buttons/ActionButton';
import { IMPORT_TEMPLATE_TYPE_LABEL } from 'constants/app/importTemplates';
import { useState } from 'react';
import Modal from 'components/commons/modal/Modal';
import TemplateInfoModal from './TemplateInfoModal';

function ImportTemplateTablePage() {
  const navigate = useNavigate();
  const tableColumns: TableColumnType<ImportTemplate>[] = [
    {
      title: 'Name',
      render: (row) => row.name,
    },
    {
      title: 'Type',
      render: (row) => IMPORT_TEMPLATE_TYPE_LABEL[row.type],
    },
    //{
    //  title: 'Mime/Type',
    //  render: (row) => row.mime_type,
    //},
  ];

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleSubmit = () => {
    navigate(urlUtils.templates.imports.detail());
  };

  return (
    <div>
      <div style={{ marginTop: '1.5rem', marginRight: '2.25rem', display: 'flex', flexFlow: 'row-reverse' }}>
        <ActionButton label="check template" onClick={() => setShowModal(true)} />
      </div>
      <ServerSideTable<ImportTemplate>
        search={true}
        columns={tableColumns}
        fetchApi={api.importTemplates.getList}
        rowClickHandler={(row: ImportTemplate) => {
          navigate(urlUtils.templates.imports.detail(row.id));
        }}
      />
      <div
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem', marginRight: '2.25rem', marginBottom: '3rem', marginLeft: '2.25rem' }}
      >
        <ActionButton label="+ add new" onClick={handleSubmit} />
      </div>

      <TemplateInfoModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
}

export default ImportTemplateTablePage;
