import api from 'api';
import { useEffect, useState } from 'react';
import urlUtils from 'utilities/urlUtils';
import { useLocation } from 'react-router-dom';
import ImportTemplateDetailsCard from 'components/job/ImportTemplateForm';
import { ImportTemplate } from 'models/importTemplates';
import ImportTemplateForm from 'components/job/ImportTemplateForm';
import { Card } from 'react-bootstrap';

function ImportTemplateDetailPage() {
  const location = useLocation();
  const id = location.pathname.replace(urlUtils.templates.imports.main() + '/', '');
  const [importInfo, setImportInfo] = useState<ImportTemplate>();

  useEffect(() => {
    api.importTemplates.get(id, false).then((res) => {
      setImportInfo(res);
    });
  }, []);

  // TODO: mettere loader

  return (
    <>
      <Card title="">
        <ImportTemplateForm id={id} />
      </Card>
    </>
  );
}

export default ImportTemplateDetailPage;
