export const validateEmail = (str: string) => {
  const re = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

  return re.test(str);
};

export const validPassword = (str: string) => {
  const rp = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

  return rp.test(str);
};
