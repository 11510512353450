import api from 'api';
import { useContext, useEffect, useState } from 'react';
import AuthContext from 'context/authContext';
import urlUtils from 'utilities/urlUtils';
import { useLocation } from 'react-router-dom';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import { TableColumnType } from 'models/table';
import { PROCESS_TYPE, PROCESS_TYPE_LABEL } from 'constants/app/accounts';
import { ProcessFamilyCmpData } from 'models/processFamilies';
import TableButton from 'components/commons/table/TableButton';
import { USER_ROLE } from 'constants/app/users';
import { AccountDetailCmpData, NewLicenseData } from 'models/accounts';
import LicenseForm from 'components/app/accounts/LicenseForm';
import Card from 'components/commons/Card';

function AccountsDetailPage() {
  const location = useLocation();
  const id = location.pathname.replace(urlUtils.accounts.main() + '/', '');
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext.store);
  const [accountInfo, setAccountInfo] = useState<AccountDetailCmpData>();
  const [processFamilies, setProcessFamilies] = useState<ProcessFamilyCmpData[]>([]);
  const [tableRefreshTrigger, setTableRefreshTrigger] = useState(false);
  const tableFetchDataCompletedHandler = () => setTableRefreshTrigger(false);

  useEffect(() => {
    api.accounts.getDetail(id).then((res) => {
      setAccountInfo(res);

      const families: ProcessFamilyCmpData[] = [];
      res.license.process_types.forEach((pt) => {
        pt.families.forEach((fam) => {
          families.push(fam);
        });
      });
      setProcessFamilies(families);
    });
  }, [tableRefreshTrigger]);

  const familyButtonClickHandler = (enabled: boolean, familyId: string) => {
    if (enabled) {
      api.accounts.disableProcessFamily(accountInfo?.id ?? '', familyId).then(() => setTableRefreshTrigger(true));
    } else {
      api.accounts.enableProcessFamily(accountInfo?.id ?? '', familyId).then(() => setTableRefreshTrigger(true));
    }
  };

  const tableColumns: TableColumnType<ProcessFamilyCmpData>[] = [
    {
      title: 'Process Type',
      render: (row) => PROCESS_TYPE_LABEL[row.type as PROCESS_TYPE] ?? '-',
    },
    {
      title: 'Family',
      render: (row) => row.code ?? '-',
    },
    {
      title: 'Enabled',
      render: (row) => {
        const familyEnabled = processFamilies.find((o) => o.id == row.id) != undefined;
        return (
          <TableButton
            tooltipText={familyEnabled ? 'Disable family' : 'Enable family'}
            iconClass={familyEnabled ? 'bi bi-check-circle' : 'bi bi-circle'}
            onClick={() => familyButtonClickHandler(familyEnabled, row.id)}
            isEnabled={authState.user.role == USER_ROLE.SuperAdmin}
          />
        );
      },
    },
  ];

  return (
    <>
      <Card title={`Account of "${accountInfo?.company_name}" info`}>
        <div className="account-detail-info-container">
          <div className="account-detail-info-subcontainer">
            <div className="account-detail-info-subtitle">Email</div>
            <div>{accountInfo?.email_address}</div>
          </div>
        </div>
        <LicenseForm accountId={accountInfo ? accountInfo.id : ''} />
      </Card>
      <ServerSideTable<ProcessFamilyCmpData>
        columns={tableColumns}
        fetchApi={api.processFamilies.get}
        forceFetchData={tableRefreshTrigger}
        onForceFetchDataCompleted={tableFetchDataCompletedHandler}
      />
    </>
  );
}

export default AccountsDetailPage;
