import { BasePaginatedRequest } from 'models/common';
import client from 'api/client';
import { UserCmpBody, UsersPaginatedData } from 'models/users';

export function get(req: BasePaginatedRequest): Promise<UsersPaginatedData> {
  return client.get(`backoffice/users`, {
    params: req,
  });
}

export function postAddUser(user: UserCmpBody): Promise<UserCmpBody> {
  return client.post(`backoffice/users`, user);
}
