import React, { ReactNode } from 'react';

interface ButtonProps {
  iconClass: string;
  onClick: () => any;
  tooltipText: string;
  isEnabled?: boolean;
}

function TableButton({ iconClass, onClick, tooltipText, isEnabled = true }: ButtonProps) {
  return (
    <button
      style={{ cursor: isEnabled ? 'pointer' : 'not-allowed' }}
      className={`${iconClass} table-button`}
      onClick={() => (isEnabled ? onClick() : undefined)}
    >
      <div className="tooltiptext" id="tooltip-id">
        {tooltipText}
      </div>
    </button>
  );
}

export default TableButton;
