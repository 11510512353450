import React from 'react';
import { TableColumnType } from 'models/table';

interface TableRowProps<T> {
  columns: TableColumnType<T>[];
  row: T;
  rKey: string;
  rIdx: number;
  rowClickHandler?: ((row: T, rIdx: number) => void) | undefined;
}

function TableRow<T>({ columns, rKey, row, rIdx, rowClickHandler = undefined }: TableRowProps<T>) {
  const isClickable = rowClickHandler != undefined;

  return (
    <tr key={`row-${rKey}`} className={isClickable ? 'clickable' : ''} onClick={isClickable ? () => rowClickHandler(row, rIdx) : undefined}>
      {columns.map((c, cIdx) => (
        <td key={`column-${rKey}-${cIdx}`}>{c.render(row, rIdx)}</td>
      ))}
    </tr>
  );
}

export default TableRow;
