export enum PROCESS_TYPE {
  Undefined = 'undefined',
  NOI = 'noi',
  LAB = 'lab',
  DOC = 'doc',
  TEST = 'test',
  TPI = 'tpi',
  EXP = 'exp',
}

export const PROCESS_TYPE_LABEL = {
  [PROCESS_TYPE.Undefined]: '',
  [PROCESS_TYPE.NOI]: 'NOI',
  [PROCESS_TYPE.LAB]: 'LAB',
  [PROCESS_TYPE.DOC]: 'DOC',
  [PROCESS_TYPE.TEST]: 'TEST',
  [PROCESS_TYPE.TPI]: 'TPI',
  [PROCESS_TYPE.EXP]: 'EXP',
};
