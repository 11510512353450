import api from 'api';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import CompanyInfoCell from 'components/app/companies/CompanyInfoCell';
import { CompanyCmpData } from 'models/companies';
import { TableColumnType } from 'models/table';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';

function CompaniesTablePage() {
  const navigate = useNavigate();
  const tableColumns: TableColumnType<CompanyCmpData>[] = [
    {
      title: 'Company',
      render: (row) => <CompanyInfoCell tableRow={row} />,
    },
  ];

  return (
    <ServerSideTable<CompanyCmpData>
      search={true}
      columns={tableColumns}
      fetchApi={api.companies.getList}
      rowClickHandler={(row: CompanyCmpData) => {
        navigate(urlUtils.companies.detail(row.id));
      }}
    ></ServerSideTable>
  );
}

export default CompaniesTablePage;
