import api from 'api';
import InputEmail from 'components/commons/InputEmail';
import InputPassword from 'components/commons/InputPassword';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import AuthContext from '../../context/authContext';
import settings from 'settings';

function BuilderCard() {
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext.store);
  const navigate = useNavigate();

  const [loginErrors, setLoginErrors] = useState<any>({ email: undefined, password: undefined });

  const onMailErrorChange = (errMsg: string) => {
    setLoginErrors({ ...loginErrors, email: errMsg });
  };

  const onPwdErrorChange = (errMsg: string) => {
    setLoginErrors({ ...loginErrors, password: errMsg });
  };

  const [email, setEmail] = useState(settings.loginEmail ?? '');
  const emailChanger = (value: string) => {
    setEmail(value);
  };

  const [password, setPassword] = useState(settings.loginPassword ?? '');
  const passwordChanger = (value: string) => {
    setPassword(value);
  };

  // CONTROLLO VALIDITà EMAIL E PASSWORD
  const formHandler = (event: any) => {
    event.preventDefault();

    try {
      api.auth.login(email, password).then((jwtToken) => {
        authDispatch({
          type: AuthContext.ACTIONS.SET_FROM_TOKEN,
          value: jwtToken,
        });
        navigate(urlUtils.main(), { replace: true });
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card className="card card-login">
      <Card.Header className="w-100">
        <div className="d-flex justify-content-center mt-2 mb-4 card-header-title">
          <img src="../assets/images/logo_notifyme_text.svg" style={{ height: '75px', width: '375px' }}></img>
        </div>
      </Card.Header>
      <Card.Body className="w-100 mb-4">
        <Card.Title className="card-header-subtitle">BACKOFFICE</Card.Title>

        <form className="card-form" onSubmit={formHandler}>
          <div className="container">
            <InputEmail value={email} onChange={emailChanger} error={loginErrors.email} onErrorChange={onMailErrorChange} />
          </div>
          <div className="container pt-2">
            <InputPassword value={password} onChange={passwordChanger} error={loginErrors.password} onErrorChange={onPwdErrorChange} />
          </div>
          <div className="d-flex justify-content-end ">
            <a href="#" className="mb-2">
              Forgot Password?
            </a>
          </div>
          <div className="container justify-content-center mt-4">
            <Button className="p-2 mb-2 btn-lg btn-group-lg text-uppercase" type="submit" variant="primary" size="lg">
              log in
            </Button>
          </div>
          <Card.Text className="text-center font-italic mt-2" style={{ lineHeight: '1.5' }}>
            By clicking Login, you agree to our
            <a href="#">Terms of Service </a>
            and that you have read our
            <a href="#"> Privacy Policy</a>, including our Cookies Policy
          </Card.Text>
        </form>
      </Card.Body>
    </Card>
  );
}

export default BuilderCard;
