import DropZone from 'components/commons/DropZone';
import { FILE_TYPE } from 'constants/utilities/devTools';
import { ImportDataSettings } from 'models/utilities';
import PeopleImportSettingsForm from './PeopleImportSettingsForm';

interface PeopleImportFormProps {
  onFilesChange: (files: any[]) => any;
  onSettingsChange: (settings: ImportDataSettings) => any;
  defaultSettings: ImportDataSettings;
}

function PeopleImportForm({ onFilesChange, onSettingsChange, defaultSettings }: PeopleImportFormProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ width: '40%', marginRight: '10%' }}>
        <PeopleImportSettingsForm onChange={onSettingsChange} defaultSettings={defaultSettings} />
      </div>
      <div style={{ width: '50%' }}>
        <DropZone maxFilesPerDrop={1} maxFilesTotalCount={1} onChange={onFilesChange} acceptedFileTypes={[FILE_TYPE.Csv]} filesName="filled template" />
      </div>
    </div>
  );
}

export default PeopleImportForm;
