import api from 'api';
import Form from 'components/commons/form/Form';
import FormDropdown from 'components/commons/form/FormDropdown';
import FormInput from 'components/commons/form/FormInput';
import Modal from 'components/commons/modal/Modal';
import { USER_ROLE_LABEL, USER_ROLE } from 'constants/app/users';
import { INPUT_TYPE } from 'constants/inputType';
import React, { useState } from 'react';
import { Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';

interface ModalProps {
  show: boolean;
  onClose: () => any;
  onSubmit: () => any;
}

function AddUserModal({ show, onClose, onSubmit }: ModalProps) {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredRole, setEnteredRole] = useState(USER_ROLE.Admin);

  const AddUserSubmitHandler = (event: any) => {
    event.preventDefault();

    api.users.postAddUser({ email: enteredEmail, role: enteredRole }).then(() => {
      onSubmit();
    });
  };
  const EmailChangeHandler = (event: any) => {
    setEnteredEmail(event.target.value);
  };
  const RoleChangeHandler = (key: any) => {
    setEnteredRole(key);
  };

  const dropdownItems = [];
  for (const item in USER_ROLE_LABEL) {
    dropdownItems.push(<Dropdown.Item eventKey={item}>{USER_ROLE_LABEL[item as USER_ROLE]}</Dropdown.Item>);
  }

  return (
    <Modal show={show} title="Add User" onHide={onClose}>
      <Form onSubmit={AddUserSubmitHandler}>
        <FormInput value={enteredEmail} label="Email" type={INPUT_TYPE.Email} onChange={EmailChangeHandler} />
        <FormDropdown label="Role" defaultValue={USER_ROLE_LABEL[enteredRole]} onSelect={RoleChangeHandler}>
          {dropdownItems}
        </FormDropdown>
      </Form>
    </Modal>
  );
}

export default AddUserModal;
