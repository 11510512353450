import api from 'api';
import { useState } from 'react';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import { USER_ROLE, USER_ROLE_LABEL } from 'constants/app/users';
import { TableColumnType } from 'models/table';
import { UsersCmpData } from 'models/users';
import { Card, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { alignPropType } from 'react-bootstrap/esm/types';
import AddUserModal from '../../../components/app/users/AddUserModal';
import ActionButton from 'components/commons/buttons/ActionButton';

function UsersTablePage() {
  const [showModal, setShowModal] = useState(false);
  const [tableRefreshTrigger, setTableRefreshTrigger] = useState(false);
  const tableFetchDataCompletedHandler = () => setTableRefreshTrigger(false);

  const modalSubmitHandler = () => {
    setShowModal(false);
    setTableRefreshTrigger(true);
  };

  const tableColumns: TableColumnType<UsersCmpData>[] = [
    {
      title: 'Email',
      render: (row) => row.email ?? '-',
    },
    {
      title: 'Role',
      render: (row) => USER_ROLE_LABEL[row.role] ?? '-',
    },
  ];

  return (
    <>
      <div className="modal-button-container">
        <ActionButton label="+ add user" onClick={() => setShowModal(true)} />
      </div>
      <ServerSideTable<UsersCmpData>
        forceFetchData={tableRefreshTrigger}
        onForceFetchDataCompleted={tableFetchDataCompletedHandler}
        columns={tableColumns}
        fetchApi={api.users.get}
        search={true}
      ></ServerSideTable>
      <AddUserModal show={showModal} onClose={() => setShowModal(false)} onSubmit={modalSubmitHandler} />
    </>
  );
}

export default UsersTablePage;
