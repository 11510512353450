import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import urlUtils from 'utilities/urlUtils';
import MenuElem from './MenuElem';
import MenuGroup from './MenuGroup';
import AuthContext, { removeJwtToken } from 'context/authContext';
import { PERSON_ROLE } from 'constants/app/people';
import { USER_ROLE } from 'constants/app/users';

const MainMenu = () => {
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext.store);

  return (
    <nav className="main-menu">
      <ul className="menu-content">
        <MenuGroup title="overview">
          <MenuElem icon="bi bi-house" label="Homepage" to={urlUtils.main()}></MenuElem>
        </MenuGroup>
        <MenuGroup title="app">
          <MenuElem icon="bi bi-person" label="People" to={urlUtils.people.main()}></MenuElem>
          <MenuElem icon="bi bi-buildings" label="Companies" to={urlUtils.companies.main()}></MenuElem>
          <MenuElem icon="bi bi-people" label="Accounts" to={urlUtils.accounts.main()}></MenuElem>
        </MenuGroup>
        <MenuGroup title="backoffice">
          <MenuElem icon="bi bi-person-gear" label="Users" to={urlUtils.users()}></MenuElem>
        </MenuGroup>
        <MenuGroup title="jobs">
          <MenuElem icon="bi bi-cloud-upload" label="Imports" to={urlUtils.jobs.imports.main()}></MenuElem>
        </MenuGroup>
        <MenuGroup title="Templates">
          <MenuElem icon="bi bi-cloud-upload" label="Import Templates" to={urlUtils.templates.imports.main()}></MenuElem>
        </MenuGroup>
        {authState.user.role == USER_ROLE.SuperAdmin && (
          <MenuGroup title="utilities">
            <MenuElem icon="bi bi-tools" label="Dev Tools" to={urlUtils.utilities.devTools.main()}></MenuElem>
            <MenuElem icon="bi bi-database-gear" label="Database" to={urlUtils.utilities.database()}></MenuElem>
          </MenuGroup>
        )}
      </ul>
      {/* <div className="menu-footer">
        <Button variant="danger" onClick={() => onLogoutButtonClick()}>
          Logout
        </Button>
      </div> */}
    </nav>
  );
};
export default MainMenu;
