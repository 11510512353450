import { isInaccessible } from '@testing-library/react';
import React from 'react';

interface ColumnsProps {
  columns: any;
}

function TableColumns({ columns }: ColumnsProps) {
  const titles = [];

  for (let i = 0; i < columns.length; i++) {
    titles.push(<th key={i}>{columns[i].title}</th>);
  }

  return <tr>{titles}</tr>;
}

export default TableColumns;
