import { INPUT_TYPE } from 'constants/inputType';
import React, { ReactNode } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface InputProps {
  label: string;
  value: any;
  type?: INPUT_TYPE;
  defaultValue?: string | number | undefined;
  placeholder?: string;
  message?: string;
  min?: number;
  disabled?: boolean;
  inputGroupSuffix?: ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  readOnly?: boolean;
}

function FormInput({
  label,
  value,
  type = INPUT_TYPE.Text,
  defaultValue = '',
  placeholder = '',
  message = '',
  min,
  disabled = false,
  inputGroupSuffix = undefined,
  onChange = undefined,
  readOnly = false,
}: InputProps) {
  const formControl = (
    <Form.Control
      value={value}
      style={{ height: '2.2rem' }}
      type={type as string}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      min={min}
    />
  );
  return (
    <Form.Group className="mb-1">
      <Form.Label>{label}</Form.Label>
      {inputGroupSuffix != null ? (
        <InputGroup contentEditable={!readOnly}>
          {formControl}
          {inputGroupSuffix}
        </InputGroup>
      ) : (
        formControl
      )}

      {message != '' && <Form.Text className="text-muted">{message}</Form.Text>}
    </Form.Group>
  );
}

export default FormInput;
