import { CompanyCmpData } from 'models/companies';
import CompanyIcon from './CompanyIcon';

interface CellProps {
  tableRow: CompanyCmpData;
}

function CompanyInfoCell({ tableRow }: CellProps) {
  return (
    <div className="company-info-cell-main-container">
      <CompanyIcon name={tableRow.name} color={tableRow.color} photo={tableRow.logo} />
      <p>{tableRow.name}</p>
    </div>
  );
}

export default CompanyInfoCell;
