import React, { ReactNode } from 'react';

interface ButtonProps {
  iconClass: string;
  onClick?: () => any;
  tooltipText?: string;
  isEnabled?: boolean;
}

function IconButton({ iconClass, onClick = () => undefined, tooltipText = '', isEnabled = true }: ButtonProps) {
  return (
    <button
      style={{ cursor: isEnabled ? 'pointer' : 'not-allowed' }}
      className={`${iconClass} icon-button`}
      onClick={() => (isEnabled ? onClick() : undefined)}
    >
      {tooltipText != '' && <div className="icon-button-tooltiptext">{tooltipText}</div>}
    </button>
  );
}

export default IconButton;
