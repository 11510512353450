import api from 'api';
import { NavLink, useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import MenuElem from './MenuElem';

type MenuElemProps = {
  title?: string;
  children: React.ReactNode;
};

const MenuGroup = ({ title, children }: MenuElemProps) => {
  return (
    <li>
      {title != null ? <div className="menu-elem-group-title">{title}</div> : null}

      <ul>{children}</ul>
    </li>
  );
};
export default MenuGroup;
