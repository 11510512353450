import React, { createContext, Dispatch, useReducer, useState } from 'react';
import { generateContext } from './baseContext';

const TOKEN_KEY = 'token';

export const getJwtToken = async () => {
  return new Promise((resolve, reject) => {
    let jwtTkn = localStorage.getItem(TOKEN_KEY);
    if (jwtTkn === 'undefined') {
      jwtTkn = null;
    }
    resolve(jwtTkn);
  });
};

export const removeJwtToken = async () => {
  localStorage.removeItem(TOKEN_KEY);
};

function parseJwt(token: string | null) {
  if (token == null || token === '') {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  const parsedUser = JSON.parse(jsonPayload);
  if (Date.now() >= parsedUser.exp * 1000) {
    return null;
  }
  return parsedUser;
}

const CLAIMS = {
  ID: 'sub',
  COMPANY_ID: 'custom:nme_company_id',
  PERSON_ID: 'custom:nme_user_id',
  ACCOUNT_ID: 'custom:nme_account_id',
  ROLE: 'custom:nme_role',
  ORG_NAME: 'custom:nme_org_name',
  FAMILY_NAME: 'family_name',
  NAME: 'name',
  EMAIL: 'email',
  IMPERSONATE: 'custom:nme_impersonate',
};

type InitialStateType = {
  user: any;
  impersonate: any;
};

function getUserFromToken(token: string) {
  const tmpUser = parseJwt(token);
  console.log('User from token', tmpUser);
  return getUserFromClaims(tmpUser);
}

function getUserFromClaims(claimsObj: any): any {
  if (claimsObj == null) {
    return null;
  }
  return {
    id: claimsObj[CLAIMS.ID],
    name: claimsObj[CLAIMS.NAME],
    familyName: claimsObj[CLAIMS.FAMILY_NAME],
    fullName: `${claimsObj[CLAIMS.NAME]} ${claimsObj[CLAIMS.FAMILY_NAME]}`,
    orgName: claimsObj[CLAIMS.ORG_NAME],
    role: claimsObj[CLAIMS.ROLE],
    accountId: claimsObj[CLAIMS.ACCOUNT_ID],
    personId: claimsObj[CLAIMS.PERSON_ID],
    companyId: claimsObj[CLAIMS.COMPANY_ID],
    impersonate: claimsObj[CLAIMS.IMPERSONATE] != null ? getUserFromClaims(convArrayClaimsToObj(JSON.parse(claimsObj[CLAIMS.IMPERSONATE]))) : null,
  };
}

function convArrayClaimsToObj(arrayClaims: any) {
  if (arrayClaims == null) {
    return null;
  }
  const objClaims: any = {};
  arrayClaims.forEach((elem: any) => {
    objClaims[elem.name] = elem.value;
  });
  return objClaims;
}
const initialState = {
  user: {},
  impersonate: null,
} as InitialStateType;

const ACTIONS = {
  SET_FROM_TOKEN: 'set_token',
  CLEAR_USER: 'clear_user',
};

function reduceFunction(oldState: any, action: any) {
  switch (action.type) {
    case ACTIONS.SET_FROM_TOKEN: {
      const token = action.value;
      console.log('ACTIONS.SET_FROM_TOKEN 1', token);
      localStorage.setItem(TOKEN_KEY, token);
      const fullUser = getUserFromToken(token);
      let impersonate = null;
      let user = null;
      if (fullUser != null) {
        impersonate = fullUser.impersonate;
        user = fullUser;
      }
      console.log('ACTIONS.SET_FROM_TOKEN 2', user);
      const newState = {
        ...oldState,
        user,
        impersonate,
      };
      return newState;
    }
    case ACTIONS.CLEAR_USER: {
      // tokenService.clearToken();
      removeJwtToken();
      const newState = {
        ...oldState,
        user: null,
        impersonate: null,
      };
      return newState;
    }
    default: {
      throw new Error();
    }
  }
}

export default {
  ...generateContext<InitialStateType>(initialState, reduceFunction),
  ACTIONS,
};
