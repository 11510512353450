export default {
  env: process.env.NODE_ENV,
  baseUrl: process.env.REACT_APP_BASE_URL,
  loginEmail: process.env.REACT_APP_LOGIN_EMAIL,
  loginPassword: process.env.REACT_APP_LOGIN_PASSWORD,
  import: {
    userCustomCodeForAutogeneratedOwnerEmail: process.env.REACT_APP_UTILITIES_IMPORT_USER_CUSTOM_CODE_FOR_AUTOGENERATED_OWNER_EMAIL == 'true' ? true : false,
    forcedEmailPrefix: process.env.REACT_APP_UTILITIES_IMPORT_FORCED_EMAIL_PREFIX ?? '',
    autogeneratedOwnerDefaultPassword: process.env.REACT_APP_UTILITIES_IMPORT_AUTOGENERATED_OWNER_DEFAULT_PASSWORD ?? '',
    autogeneratedOwnerPrefix: process.env.REACT_APP_UTILITIES_IMPORT_AUTOGENERATED_OWNER_PREFIX ?? '',
    autogeneratedOwnerDomain: process.env.REACT_APP_UTILITIES_IMPORT_AUTOGENERATED_OWNER_DOMAIN ?? '',
    forcedEmailDomain: process.env.REACT_APP_UTILITIES_IMPORT_FORCED_EMAIL_DOMAIN ?? '',
    forcedEmailForTest: process.env.REACT_APP_UTILITIES_IMPORT_FORCED_EMAIL_FOR_TEST == 'true' ? true : false,
    importFileTemplate: Number(process.env.REACT_APP_UTILITIES_IMPORT_FILE_TEMPLATE) ?? 0,
    outputFileCustomSuffix: process.env.REACT_APP_UTILITIES_IMPORT_OUTPUT_FILE_CUSTOM_SUFFIX ?? '',
    buCentricRoles: process.env.REACT_APP_UTILITIES_IMPORT_BU_CENTRIC_ROLES == 'true' ? true : false,
  },
  showTestPushNotification: process.env.REACT_APP_SHOW_TEST_PUSH_NOTIFICATION == 'true' ? true : false,
};
