import { useLocation } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import api from 'api';
import { TableColumnType } from 'models/table';
import { PersonCmpData } from 'models/people';
import PeopleInfoCell from 'components/app/people/PeopleInfoCell';
import TableButton from 'components/commons/table/TableButton';
import { PERSON_ROLE_LABEL } from 'constants/app/people';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import Card from 'components/commons/Card';
import { useState } from 'react';
import { CompanyData } from 'models/companies';
import CompanyApiKeyComponent from '../../../components/app/companies/CompanyApiKeyComponent';
import CompanyUpdateForm from '../../../components/app/companies/CompanyUpdateForm';

function CompanyDetailPage() {
  const location = useLocation();
  const id = location.pathname.replace(urlUtils.companies.main() + '/', '');
  const [companyInfo, SetCompanyInfo] = useState<CompanyData>({
    id: '',
    updated_at: new Date(),
    name: '',
    address: '',
    public_api_key: '',
    city: '',
    country: '',
    zip_postal_code: '',
    phone: '',
    email: '',
  });

  const impersonate = (id: string) => {
    api.people.impersonate(id).then((res) => {
      window.open(res.impersonation_link, '_blank');
    });
  };

  const tableColumns: TableColumnType<PersonCmpData>[] = [
    {
      title: 'Person',
      render: (row) => <PeopleInfoCell tableRow={row} />,
    },
    {
      title: 'Email',
      render: (row) => row.email_address,
    },
    {
      title: 'Role',
      render: (row) => PERSON_ROLE_LABEL[row.role],
    },
    {
      title: '',
      render: (row) => <TableButton tooltipText="Impersonate" iconClass="bi bi-eye-fill" onClick={() => impersonate(row.id)} />,
    },
  ];

  return (
    <>
      <Card title={`"${companyInfo?.name}" Info`}>
        <CompanyUpdateForm id={id} companyInfo={companyInfo} onChange={(data) => SetCompanyInfo(data)} />
      </Card>
      <ServerSideTable<PersonCmpData> search={true} columns={tableColumns} fetchApi={(req) => api.companies.getCompanyPeople(id, req)}></ServerSideTable>
    </>
  );
}

export default CompanyDetailPage;
