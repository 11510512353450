
import { ImportTemplateItemMultiPackageSettingsType } from 'models/importTemplates';
import PackageFieldSelect from './inputs/PackageFieldSelect';

type Props = {
  data: ImportTemplateItemMultiPackageSettingsType;
  setFormData: (name: string, value: any) => void
}

export default function ImportTemplateItemMultiPackageSettings({ data, setFormData }: Props) {
  return <>
  <PackageFieldSelect value={data.package_identifier_field} onChange={(newVal) => setFormData('package_identifier_field', newVal)} />
  </>;
}