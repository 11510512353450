import api from 'api';
import PeopleIcon from 'components/app/people/PeopleIcon';
import DropZone from 'components/commons/DropZone';
import ActionButton from 'components/commons/buttons/ActionButton';
import IconButton from 'components/commons/buttons/IconButton';
import Form from 'components/commons/form/Form';
import FormInput from 'components/commons/form/FormInput';
import Modal from 'components/commons/modal/Modal';
import { FILE_TYPE } from 'constants/utilities/devTools';
import { ImportTemplateDetail, ImportTemplateGeneralInfo, ImportTemplateTypeEnum } from 'models/importTemplates';
import { useState } from 'react';
import { InputGroup, Spinner } from 'react-bootstrap';

interface FieldCheckProps {
  check: boolean;
}
function FieldCheckCmp({ check }: FieldCheckProps) {
  return (
    <InputGroup.Text id="basic-addon2" className="form-input-text-addon">
      <IconButton iconClass={`bi bi-${check ? 'check' : 'x'}-circle bg-transparent text-${check ? 'success' : 'danger'}`} />
    </InputGroup.Text>
  );
}

interface Props {
  show: boolean;
  onClose?: () => any;
}

function TemplateInfoModal({ show, onClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ImportTemplateGeneralInfo | null>(null);

  const handleFileChange = (files: File[]) => {
    if (files.length > 0) {
      const file = files[0];
      setLoading(true);

      let base64Data = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        if (reader?.result && typeof reader.result === 'string') {
          const resultParts = reader.result.split(',');
          base64Data = resultParts[1];
        }
        api.importTemplates
          .getInfo(base64Data)
          .then((res) => {
            setData(res);
          })
          .finally(() => {
            setLoading(false);
          });
      };
    } else {
      setData(null);
    }
  };

  return (
    <Modal show={show} title={'Check template info'} onHide={onClose}>
      {loading ? (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Spinner animation="border" variant="primary" style={{ maxHeight: '4rem', maxWidth: '4rem' }}></Spinner>
        </div>
      ) : (
        <>
          <DropZone maxFilesPerDrop={1} maxFilesTotalCount={1} onChange={handleFileChange} acceptedFileTypes={[FILE_TYPE.Excel]} />
          {data != null && (
            <Form>
              <FormInput label="Type" value={data.type} disabled={true} inputGroupSuffix={<FieldCheckCmp check={data.type_check} />} />
              <FormInput label="Version" value={data.version} disabled={true} inputGroupSuffix={<FieldCheckCmp check={data.version_check} />} />
              <FormInput label="Key" value={data.key} disabled={true} inputGroupSuffix={<FieldCheckCmp check={data.key_check} />} />
              <div style={{ display: 'flex' }}>
                Has hidden worksheet:
                <IconButton
                  iconClass={`bi bi-${data.has_hidden_worksheet ? 'check' : 'x'}-circle bg-transparent text-${
                    data.has_hidden_worksheet ? 'success' : 'danger'
                  }`}
                />
              </div>
            </Form>
          )}
        </>
      )}
    </Modal>
  );
}

export default TemplateInfoModal;
