import api from 'api';
import Form from 'components/commons/form/Form';
import { AccountDetailCmpData, NewLicenseData } from 'models/accounts';
import AuthContext from 'context/authContext';
import React, { useContext, useEffect, useState } from 'react';
import { USER_ROLE } from 'constants/app/users';
import { FormCheck } from 'react-bootstrap';
import { isValidInputTimeValue } from '@testing-library/user-event/dist/utils';

interface LicenseFormProps {
  accountId: string;
}

interface LicenseInputProps {
  label: string;
  name: string;
  value: number;
  onChange: (name: string, value: number) => any;
  unitOfMeasure?: string;
}

function LicenseInput({ label, name, value, onChange, unitOfMeasure = '' }: LicenseInputProps) {
  const [checked, setChecked] = useState(value === -1);
  const [inputValue, setInputValue] = useState<number>(0);

  useEffect(() => {
    setChecked(value === -1);
    if (value !== -1) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div className="form-label-main-container">
      <div className="form-label-and-checkbox">
        {label}
        <FormCheck checked={checked} type="checkbox" label="Unlimited" onChange={(e) => onChange(name, e.target.checked ? -1 : inputValue)} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <input className="form-value-input" type="number" min={-1} value={value} onChange={(e) => onChange(name, +e.target.value)} />
        <p style={{ fontSize: '0.8rem', paddingTop: '5px', paddingLeft: '5px' }}>{unitOfMeasure}</p>
      </div>
    </div>
  );
}

function LicenseForm({ accountId }: LicenseFormProps) {
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext.store);
  const [formData, setFormData] = useState<NewLicenseData>({
    person_free_licenses_count: 0,
    person_standard_licenses_count: 0,
    storage_size_byte: 0,
  });
  const fLicenseCountString = 'person_free_licenses_count';
  const sLicenseCountString = 'person_standard_licenses_count';
  const storageSizeString = 'storage_size_byte';

  useEffect(() => {
    if (accountId != '') {
      api.accounts.getLicense(accountId).then((res) => {
        setFormData(res);
      });
    }
  }, [accountId]);

  const formDataChangeHandler = (name: string, value: any) => {
    const fd: any = { ...formData };
    fd[name] = value;
    setFormData(fd);
  };

  const infoSubmitButtonClickHandler = (event: any) => {
    event.preventDefault();
    api.accounts.updateLicense(accountId ?? '', formData);
  };

  return (
    <Form onSubmit={infoSubmitButtonClickHandler} disableSubmitBtn={authState.user.role != USER_ROLE.SuperAdmin}>
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <LicenseInput label="Free licenses" name={fLicenseCountString} value={formData.person_free_licenses_count} onChange={formDataChangeHandler} />
        <LicenseInput label="Standard licenses" name={sLicenseCountString} value={formData.person_standard_licenses_count} onChange={formDataChangeHandler} />
        <LicenseInput
          label="Storage size"
          name={storageSizeString}
          value={formData.storage_size_byte}
          onChange={formDataChangeHandler}
          unitOfMeasure="(bytes)"
        />
      </div>
    </Form>
  );
}

export default LicenseForm;
