import React, { ReactNode } from 'react';
import { Card as BSCard } from 'react-bootstrap';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  title: string;
}

function Card({ children, title }: CardProps) {
  return (
    <BSCard>
      <div className="card-title">{title}</div>
      {children}
    </BSCard>
  );
}

export default Card;
