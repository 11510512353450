export enum INPUT_TYPE {
  Button = 'button',
  CheckBox = 'checkbox',
  Date = 'date',
  Email = 'email',
  File = 'file',
  Image = 'image',
  Number = 'number',
  Password = 'password',
  Phone = 'tel',
  Text = 'text',
  Url = 'url',
}
