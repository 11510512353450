import { ACTION_STATE } from 'constants/actionState';
import { ReactNode, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

interface ButtonProps {
  label: string;
  lightStyle?: boolean;
  onClick: () => any;
  state?: ACTION_STATE;
}

function ActionButton({ label, lightStyle, onClick, state = ACTION_STATE.Idle }: ButtonProps) {
  const [content, SetContent] = useState<ReactNode>(<></>);

  useEffect(() => {
    switch (state) {
      case ACTION_STATE.Idle:
        SetContent(<>{label}</>);
        break;
      case ACTION_STATE.Loading:
        SetContent(
          <>
            Loading <Spinner animation="border" style={{ color: 'white', maxHeight: '1rem', maxWidth: '1rem' }}></Spinner>
          </>,
        );
        break;
      case ACTION_STATE.Success:
        SetContent(
          <>
            Success <i className="bi bi-check" style={{ color: 'white', fontSize: '1rem' }} />
          </>,
        );
        break;
      case ACTION_STATE.Error:
        SetContent(
          <>
            Error <i className="bi bi-x" style={{ color: 'white', fontSize: '1rem' }} />
          </>,
        );
        break;
      default:
        break;
    }
  }, [state]);

  return (
    <button
      className={`action-button ${lightStyle ? 'light' : ''}`}
      style={{ cursor: state != ACTION_STATE.Idle ? 'not-allowed' : '' }}
      onClick={state == ACTION_STATE.Idle ? onClick : undefined}
    >
      {content}
    </button>
  );
}

export default ActionButton;
