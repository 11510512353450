import React, { ReactNode } from 'react';
import { Form as BSForm } from 'react-bootstrap';

interface FormProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  onSubmit?: (event: any) => any;
  disableSubmitBtn?: boolean;
}

function Form({ children, onSubmit = undefined, disableSubmitBtn = false }: FormProps) {
  return (
    <BSForm className="common-form" onSubmit={onSubmit}>
      {children}
      {onSubmit != undefined && (
        <input type="submit" value="Submit" className={`form-submit-button ${disableSubmitBtn ? 'disabled' : ''}`} disabled={disableSubmitBtn} />
      )}
    </BSForm>
  );
}

export default Form;
