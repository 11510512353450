import React, { ReactNode } from 'react';
import Modal from './Modal';

interface MessageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  title: string;
  show: boolean;
  yesLabel?: string;
  noLabel?: string;
  cancelLabel?: string;
  onYes?: () => any;
  onNo?: () => any;
  onCancel?: () => any;
}

function MessageBox({ children, title, show, onYes, onNo, onCancel, yesLabel = 'yes', noLabel = 'no', cancelLabel = 'cancel' }: MessageBoxProps) {
  return (
    <Modal show={show} title={title} onHide={onCancel} hasCloseButton={onCancel ? true : false}>
      <div>{children}</div>
      <div className="message-box-buttons-main-container">
        <div className="message-box-buttons-child-container">
          {onYes && (
            <button className="message-box-action-button yes" onClick={onYes}>
              {yesLabel}
            </button>
          )}
          {onNo && (
            <button className="message-box-action-button no" onClick={onNo}>
              {noLabel}
            </button>
          )}
        </div>
        <div className="message-box-buttons-child-container cancel">
          {onCancel && (
            <button className="message-box-action-button cancel" onClick={onCancel}>
              {cancelLabel}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default MessageBox;
