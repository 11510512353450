import { useEffect, useState } from 'react';
import { validateEmail } from '../../pages/utilities/validationUtils';

interface PropsEmailType {
  value: string | undefined;
  onChange: (v: string) => void;
  error: string | undefined;
  onErrorChange: (v: string) => void;
}

function InputEmail({ value, onChange, error, onErrorChange }: PropsEmailType) {
  const [enteredEmail, setEnteredEmail] = useState(value);
  const [mailValid, setMailValid] = useState(true);
  const [errorEmail, setErrorEmail] = useState(error);

  useEffect(() => {
    setEnteredEmail(value);
    setErrorEmail(error);
  }, [value, error]);

  const setMailOnChange = (e: any) => {
    const emailValue = e.target.value;
    setEnteredEmail(emailValue);

    if (emailValue.length == 0) {
      onErrorChange("'Email' is not a valid email'");
    } else if (!validateEmail(emailValue)) {
      setMailValid(false);
      onErrorChange("'Email' is required");
    } else {
      setMailValid(true);
    }
    onChange(emailValue);
  };

  return (
    <span>
      <label className="card-label mt-0 mb-1">Email:</label>
      <input
        className={!mailValid ? 'card-email-input-ko' : ' card-email-input'}
        style={{ padding: '0.5rem 0.5rem 0.5rem 1rem' }}
        placeholder="Enter your email"
        onChange={setMailOnChange}
        value={enteredEmail}
        type="email"
        required
      ></input>
      <div className="" style={{ minHeight: '28px' }}>
        {!mailValid && (
          <p
            style={{
              color: '#ff4d4f',
              fontSize: '14px',
              transition: 'color 0.3s',
              paddingTop: '0px',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            {errorEmail}
          </p>
        )}
      </div>
    </span>
  );
}
export default InputEmail;
