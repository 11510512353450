import { Card, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';

function DevToolsPage() {
  const navigate = useNavigate();
  return (
    <Card title="Dev tools">
      <ListGroup variant="flush">
        <ListGroup.Item action onClick={() => navigate(urlUtils.utilities.devTools.hangfire())}>
          Hangfire <i className="bi bi-arrow-right-short"></i>
        </ListGroup.Item>
        <ListGroup.Item action onClick={() => navigate(urlUtils.utilities.devTools.peopleImport())}>
          People import <i className="bi bi-arrow-right-short"></i>
        </ListGroup.Item>
        <ListGroup.Item action onClick={() => navigate(urlUtils.utilities.devTools.workflow())}>
          Workflow import <i className="bi bi-arrow-right-short"></i>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}

export default DevToolsPage;
