export default {
  login: () => '/login',
  people: {
    main: () => '/people',
    table: () => '/people',
    detail: (id = ':id') => `/people/${id}`,
  },
  companies: {
    main: () => '/companies',
    table: () => '/companies',
    detail: (id = ':id') => `/companies/${id}`,
  },
  accounts: {
    main: () => '/accounts',
    table: () => '/accounts',
    detail: (id = ':id') => `/accounts/${id}`,
  },
  users: () => '/users',
  jobs: {
    imports: {
      main: () => '/jobs/imports',
      table: () => '/jobs/imports',
      detail: (id = ':id') => `/jobs/imports/${id}`,
    },
  },
  templates: {
    imports: {
      main: () => '/importTemplates/imports',
      table: () => '/importTemplates/imports',
      detail: (id = ':id') => `/importTemplates/imports/${id}`,
    },
  },
  utilities: {
    devTools: {
      main: () => '/utilities/devtools',
      hangfire: () => '/utilities/devtools/hangfire',
      peopleImport: () => '/utilities/devtools/people-import',
      workflow: () => '/utilities/devtools/workflow',
      workflowImport: () => '/utilities/devtools/workflow/import',
    },
    database: () => '/utilities/database',
  },
  main: () => '/home',
};
