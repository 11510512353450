import { table } from 'console';
import { PersonCmpData } from 'models/people';
import React from 'react';
import PeopleIcon from './PeopleIcon';

interface CellProps {
  tableRow: PersonCmpData;
}

function PeopleInfoCell({ tableRow }: CellProps) {
  return (
    <div className="people-info-cell-main-container">
      <PeopleIcon name={tableRow.first_name} surname={tableRow.family_name} color={tableRow.color} photo={tableRow.photo} />
      <p>
        {tableRow.first_name} {tableRow.family_name}
      </p>
    </div>
  );
}

export default PeopleInfoCell;
