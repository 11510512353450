import React, { createContext, Dispatch, ReactElement, useMemo, useReducer, useState } from 'react';

type DispatchType = {
  type: string;
  value?: any;
};

export function generateContext<T>(
  initialState: T,
  reducerFnc: (oldState: any, action: any) => any
) {
  const AppContext = createContext<{
    state: T;
    dispatch: React.Dispatch<DispatchType>;
  }>({
    state: initialState,
    dispatch: () => null
  });

  function StateProvider({ children }: any): ReactElement {
    const [state, dispatch] = useReducer(reducerFnc, initialState);
    const memoValue = useMemo(
      () => ({
        dispatch,
        state
      }),
      [dispatch, state]
    );

    return <AppContext.Provider value={memoValue}>{children}</AppContext.Provider>;
  }

  return { store: AppContext, StateProvider };
}
