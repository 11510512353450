import { useState, useEffect } from 'react';
import { validPassword } from '../../pages/utilities/validationUtils';
import { error } from 'console';

interface PropsPswdType {
  value: string | undefined;
  onChange: (v: string) => void;
  error: string | undefined;
  onErrorChange: (v: string) => void;
}

function InputPassword({ value, onChange, error, onErrorChange }: PropsPswdType) {
  const [enteredPassword, setEnteredPassword] = useState(value);
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordFocus, setPswdOnFocus] = useState(false);

  const [errorPwd, seterrorPwd] = useState(error);

  useEffect(() => {
    setEnteredPassword(value);
    seterrorPwd(error);
  }, [value, error]);

  /*** SET PASSWORD + LOGIN ERRORS ***/
  const setPswdOnChange = (e: any) => {
    const pswValue = e.target.value;
    setEnteredPassword(pswValue);

    if (pswValue.length == 0) {
      onErrorChange('Password is required');
    } else if (checkPasswordValidation(pswValue)) setPasswordValid(true);
    else {
      setPasswordValid(false);
    }

    onChange(pswValue);
  };

  /*** PASSWORD VALIDATION ***/
  const checkPasswordValidation = (setPswd: any) => {
    if (!validPassword(setPswd)) {
      onErrorChange('Password is not valid password');
      return false;
    }

    return true;
  };

  /*** EYE HANDLER ***/
  const [showEye, setShowEye] = useState(false);

  const eyeHandler = () => {
    const input = document.getElementById('pwd') as HTMLInputElement;

    if (input.type === 'password') setShowEye(true);
    else setShowEye(false);
  };

  return (
    <span>
      <label className="card-label mb-1">Password:</label>
      <span
        className={`${!passwordValid ? 'card-pswd-input-ko' : 'card-pswd-input'} ${
          passwordFocus ? `card-pswd-input-focus ${!passwordValid ? 'error' : 'ok'}` : undefined
        }`}
      >
        <input
          className="input-affix"
          placeholder="Enter your password"
          onChange={setPswdOnChange}
          type={showEye ? 'text' : 'password'}
          value={enteredPassword}
          id="pwd"
          onFocus={() => setPswdOnFocus(true)}
          // onBlur={() => setPasswordFocus(false)}
          required
        ></input>
        <span className="input-suffix">
          <span
            className={
              showEye
                ? 'anticon anticon-eye-invisible ant-input-password-icon bi bi-eye'
                : 'anticon anticon-eye-invisible ant-input-password-icon bi bi-eye-slash'
            }
            role="img"
            aria-label="eye-invisible"
            onClick={eyeHandler}
          ></span>
        </span>
      </span>
      <div style={{ minHeight: '28px' }}>
        {!passwordValid && (
          <p
            style={{
              color: '#ff4d4f',
              fontSize: '14px',
              transition: 'color 0.3s',
              paddingTop: '0px',
              marginTop: '0px',
              marginBottom: '0px',
            }}
          >
            {errorPwd}
          </p>
        )}
      </div>
    </span>
  );
}

export default InputPassword;
