import api from 'api';
import ActionButton from 'components/commons/buttons/ActionButton';
import Card from 'components/commons/Card';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import TableButton from 'components/commons/table/TableButton';
import { PROCESS_TYPE } from 'constants/app/accounts';
import { TableColumnType } from 'models/table';
import { WorkflowAttributesCmpData, WorkflowAttributesPaginatedRequest } from 'models/utilities';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';

function WorkflowTableCard() {
  const navigate = useNavigate();

  const addButtonClickHandler = () => {
    navigate(urlUtils.utilities.devTools.workflowImport());
  };

  // const upgradeButtonClickHandler = (type: PROCESS_TYPE, family: string, version: number) => {};

  const tableColumns: TableColumnType<WorkflowAttributesCmpData>[] = [
    {
      title: 'Process Type',
      render: (row) => row.process_type,
    },
    {
      title: 'Process Family',
      render: (row) => row.process_family,
    },
    {
      title: 'Version',
      render: (row) => row.version,
    },
    {
      title: 'Enabled',
      render: (row) => <i className={`${row.enabled ? 'bi bi-check' : 'bi bi-x'}`} />,
    },
    // {
    //   title: '',
    //   render: (row) => (
    //     <TableButton
    //       iconClass="bi bi-arrow-up-circle"
    //       // toLowerCase() provvisorio. TODO: allineare PROCESS_TYPE con i valori passati dal BackEnd
    //       onClick={() => upgradeButtonClickHandler(row.process_type.toLowerCase() as PROCESS_TYPE, row.process_family, row.version)}
    //       tooltipText="Upgrade version"
    //     />
    //   ),
    // },
  ];

  const paginatedRequest: WorkflowAttributesPaginatedRequest = {
    GroupedByFamily: true,
  };

  return (
    <>
      <Card title="Workflows">
        <div style={{ alignSelf: 'end' }}>
          <ActionButton label="+ add workflow" onClick={addButtonClickHandler} />
        </div>
        <ServerSideTable columns={tableColumns} customPaginatedRequest={paginatedRequest} fetchApi={api.utilities.devTools.getWorkflows} />
      </Card>
    </>
  );
}

export default WorkflowTableCard;
