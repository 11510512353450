import api from 'api';
import { useNavigate } from 'react-router-dom';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import { TableColumnType } from 'models/table';
import urlUtils from 'utilities/urlUtils';
import { AccountCmpData } from 'models/accounts';

function AccountsTablePage() {
  const navigate = useNavigate();
  const tableColumns: TableColumnType<AccountCmpData>[] = [
    {
      title: 'Company',
      render: (row) => row.company_name ?? '-',
    },
    {
      title: 'Email',
      render: (row) => row.email_address ?? '-',
    },
  ];

  return (
    <ServerSideTable<AccountCmpData>
      rowClickHandler={(row: AccountCmpData) => {
        navigate(urlUtils.accounts.detail(row.id));
      }}
      columns={tableColumns}
      fetchApi={api.accounts.get}
      search={true}
    />
  );
}

export default AccountsTablePage;
