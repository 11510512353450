import Header from 'components/layout/Header';
import SiderMenu from 'components/menu/SiderMenu';
import React, { ReactNode } from 'react';
import AuthContext from '../../context/authContext';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

function MainLayout({ children }: Props) {
  const { state } = React.useContext(AuthContext.store);
  const siderOpen = true;
  const showSiderMenu = siderOpen ? 'my-sider-menu' : 'my-sider-menu-none';
  return (
    <div
      className="main-layout-container d-flex flex-column"
      style={{
        minHeight: '100%',
      }}
    >
      <Header />
      <div className="flex-fill d-flex ">
        <SiderMenu className={showSiderMenu} />
        <main className="flex-fill">{children}</main>
      </div>
    </div>
  );
}

export default MainLayout;
