import { BasePaginatedData, BasePaginatedRequest } from "models/common";
import { useEffect, useMemo, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { OptionType } from "./FormSelect";
import { PaginationElementType } from "models/table";
import { Option } from "react-bootstrap-typeahead/types/types";

interface Props {
  label: string;
  fetchApi: (req: BasePaginatedRequest) => Promise<BasePaginatedData<any>>;
  disabled?: boolean;
  value?: any;
  labelKey: string | ((option: Option) => string);
  onSelect?: (key: any) => any;
}

export default function FormApiSearchableDropdown({fetchApi, label, disabled, value, labelKey, onSelect}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [tablePage, setTablePage] = useState<PaginationElementType>({ limit: 5, offset: 0, q: '' });

  const handleSearch = (query: string) => {
    setIsLoading(true);

    const request: BasePaginatedRequest = {
      limit: tablePage.limit,
      offset: tablePage.offset,
      q: query,
    };
    fetchApi(request)
      .then((res: any) => {
        console.log("fetchApi res", res)
        setOptions(res.content);
        setIsLoading(false);
      });
  };

  const selected = useMemo(() => value != null ? [value] : undefined, [value]);

  console.log("DEFAULT VALUE", value)

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  useEffect(() => {
    handleSearch('');
  }, [])

  return <AsyncTypeahead
  filterBy={filterBy}
  id="async-example"
  selected={selected}
  isLoading={isLoading}
  labelKey={labelKey}
  minLength={3}
  onSearch={handleSearch}
  options={options}
  disabled={disabled}
  placeholder={label}
  onChange={(val) => {
    const selOpt = val.length > 0 ? val[0] : undefined;
    onSelect?.(selOpt);
  }}
  /* renderMenuItemChildren={(option: Item) => (
    <>
      <img
        alt={option.login}
        src={option.avatar_url}
        style={{
          height: '24px',
          marginRight: '10px',
          width: '24px',
        }}
      />
      <span>{option.login}</span>
    </>
  )} */
/>
}