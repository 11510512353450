import React, { useContext, useEffect, useState } from 'react';
import { allRoutes, auth, unauth } from 'router';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import MainLayout from 'components/layout';
import urlUtils from 'utilities/urlUtils';
import AuthContext, { getJwtToken } from 'context/authContext';
import RouteContext from 'context/routeContext';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function App() {
  const [loading, setLoading] = useState(true);
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext.store);
  const { state: routeState, dispatch: routeDispatch } = useContext(RouteContext.store);
  const userIsLogged = authState.user != null && authState.user.id != null;

  useEffect(() => {
    getJwtToken()
      .then((jwtToken) => {
        console.log('curr jwtToken', jwtToken);
        if (jwtToken != null) {
          authDispatch({
            type: AuthContext.ACTIONS.SET_FROM_TOKEN,
            value: jwtToken,
          });
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const location = useLocation();
  useEffect(() => {
    let title = 'NotifyMe - BackOffice';
    const found = allRoutes.find((o) => o.path === location.pathname);
    if (found != null) {
      title = `${title} - ${found.title}`;
    }
    routeDispatch({
      type: RouteContext.ACTIONS.SET_CURRENT_ROUTE_DATA,
      value: found,
    });
    document.title = title;
  }, [location]);

  if (loading) {
    return <div>LOADING...</div>;
  }

  return (
    <Routes>
      {auth.map((r) => (
        <Route key={r.name} path={r.path} element={userIsLogged ? <MainLayout>{r.element}</MainLayout> : <Navigate to={urlUtils.login()} />} />
      ))}
      {unauth.map((r) => (
        <Route key={r.name} path={r.path} element={!userIsLogged ? r.element : <Navigate to={urlUtils.main()} />} />
      ))}
    </Routes>
  );
}

export default App;
