import api from 'api';
import ActionButton from 'components/commons/buttons/ActionButton';
import Card from 'components/commons/Card';
import HangfireJobViewer from 'components/job/HangfireJobViewer';
import { ImportDataSettings } from 'models/utilities';
import { useState } from 'react';
import PeopleImportForm from './PeopleImportForm';
import settings from 'settings';

function PeopleImportCard() {
  const [droppedFiles, setDroppedFiles] = useState<any[]>([]);
  // TODO: gestire meglio il base64
  const [base64, setBase64] = useState<any>();
  const [jobId, setJobId] = useState<string | undefined>();

  const [importSettings, setImportSettings] = useState<ImportDataSettings>({
    user_custom_code_for_autogenerated_owner_email: settings.import.userCustomCodeForAutogeneratedOwnerEmail,
    forced_email_prefix: settings.import.forcedEmailPrefix,
    autogenerated_owner_default_password: settings.import.autogeneratedOwnerDefaultPassword,
    autogenerated_owner_prefix: settings.import.autogeneratedOwnerPrefix,
    autogenerated_owner_domain: settings.import.autogeneratedOwnerDomain,
    forced_email_domain: settings.import.forcedEmailDomain,
    forced_mail_for_test: settings.import.forcedEmailForTest,
    import_file_template: settings.import.importFileTemplate,
    output_file_custom_suffix: settings.import.outputFileCustomSuffix,
    bu_centric_roles: settings.import.buCentricRoles,
  });

  const droppedFileChangeHandler = (files: any[]) => {
    setDroppedFiles(files);
    // TODO: gestire meglio il base64
    if (files.length > 0) {
      getAndSetBase64(files[0]);
    }
  };

  const importData = () => {
    api.utilities.devTools.importPeople({ file: base64, settings: importSettings }).then((res) => setJobId(res.uid));
  };

  // TODO: gestire meglio il base64
  const getAndSetBase64 = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBase64(reader.result);
    };
    reader.onerror = function (error) {
      setBase64(error);
    };
  };

  return (
    <Card title="Import People">
      <div className="dev-tools-download-button-container">
        <ActionButton label="download template" onClick={() => undefined} />
      </div>
      <div style={{ alignSelf: 'center', marginTop: '20px', marginBottom: '20px' }}>
        and fill it with the data you want to import. Then upload the file using the area below
      </div>
      <PeopleImportForm
        onFilesChange={droppedFileChangeHandler}
        onSettingsChange={(settings) => setImportSettings(settings)}
        defaultSettings={importSettings}
      />
      {droppedFiles.length > 0 && (
        <div style={{ marginTop: '20px', alignSelf: 'center' }}>
          <ActionButton label="import" onClick={() => importData()} />
        </div>
      )}
      {jobId != null ? <HangfireJobViewer jobId={jobId} /> : null}
    </Card>
  );
}

export default PeopleImportCard;
//className="dev-tools-upload-container"
