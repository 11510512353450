import { BasePaginatedData } from './common';
import { PersonData } from './people';

export type JobImportType = {
  id: string;
  job_id: string;
  type: string;
  status: string;
  creatorId: string;
  creator?: PersonData;
  count?: any;
  import_lines?: JobImportLineType[];
  status_updated_at?: string;
  natural_key?: string;
};

export type JobImportLineType = {
  converted: boolean;
  raw_data: string;
  info: string;
};

export type JobImportWithCounts = JobImportType & {
  succeeded_count: number;
  failed_count: number;
  pending_count: number;
};

export enum JobTypeEnum {
  IMPORT_PROJECTS = 'import-projects',
  IMPORT_PACKAGES = 'import-packages',
  IMPORT_PACKAGE_ITEMS = 'import-package-items',
  IMPORT_PACKAGE_STEPS = 'import-package-steps',
}

export const JOB_TYPE_VALUES = [
  {
    value: JobTypeEnum.IMPORT_PROJECTS,
    label: 'Projects',
  },
  {
    value: JobTypeEnum.IMPORT_PACKAGES,
    label: 'Packages',
  },
  {
    value: JobTypeEnum.IMPORT_PACKAGE_ITEMS,
    label: 'Items',
  },
  {
    value: JobTypeEnum.IMPORT_PACKAGE_STEPS,
    label: 'Steps',
  },
];

export class JobImportLinesPaginatedData extends BasePaginatedData<JobImportLineType> {}
export class JobImportsPaginatedData extends BasePaginatedData<JobImportWithCounts> {}
