
import FormDropdown from 'components/commons/form/FormDropdown';
import { Dropdown } from 'react-bootstrap';

type Props = {
  value?: string;
  onChange: (newVal: string) => void;
}

const PACKAGE_FIELDS = {
  '': '',
  'Name': 'Name',
  'Description': 'Description',
}

export default function PackageFieldSelect({ value, onChange }: Props) {
  return <FormDropdown label="Type" defaultValue={value ?? ''} onSelect={(e) => onChange(e)} disabled={false}>
    {Object.keys(PACKAGE_FIELDS).map(k => <Dropdown.Item key={k} eventKey={k}>{(PACKAGE_FIELDS as any)[k]}</Dropdown.Item>)}
  </FormDropdown>;
}