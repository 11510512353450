import { BasePaginatedRequest } from 'models/common';
import client from 'api/client';
import { CompaniesPaginatedData, CompanyData, CompanyUpdateData, GenerateApiKeyResponseData } from 'models/companies';
import { PeoplePaginatedData } from 'models/people';

export function getList(req: BasePaginatedRequest): Promise<CompaniesPaginatedData> {
  return client.get(`backoffice/companies`, {
    params: req,
  });
}

export function getCompanyPeople(id: string, req: BasePaginatedRequest): Promise<PeoplePaginatedData> {
  return client.get(`backoffice/companies/${id}/people`, {
    params: req,
  });
}

export function getCompanyDetails(id: string): Promise<CompanyData> {
  return client.get(`backoffice/companies/${id}`);
}

export function updateCompany(id: string, data: CompanyUpdateData): Promise<CompanyData> {
  return client.put(`backoffice/companies/${id}`, data);
}

export function generateNewKey(id: string): Promise<GenerateApiKeyResponseData> {
  return client.get(`backoffice/companies/${id}/apikey`);
}
