import api from 'api';
import ServerSideTable from 'components/commons/table/ServerSideTable';
import { TableColumnType } from 'models/table';
import { JOB_TYPE_VALUES, JobImportType, JobImportWithCounts } from 'models/jobs';
import { useNavigate } from 'react-router-dom';
import urlUtils from 'utilities/urlUtils';
import { Tooltip } from 'react-bootstrap';
import { getDateTimeString } from 'utilities/functionUtils';

function JobImportsTablePage() {
  const navigate = useNavigate();
  const tableColumns: TableColumnType<JobImportWithCounts>[] = [
    {
      title: 'key',
      render: (row) => row.natural_key,
    },
    {
      title: 'Last Update',
      render: (row) => getDateTimeString(row.status_updated_at),
    },
    {
      title: 'Count',
      render: (val) =>
        val.count != null ? (
          <div>
            (<i className="bi bi-check2-circle" style={{ color: '#a7c50' }} title="Successfully imported"></i>
            {' ' + val.succeeded_count}
            <i className="bi bi-exclamation-circle" title="Failed" style={{ color: '#d32f2f' }}></i>
            {' ' + val.failed_count}-<i className="bi bi-clock-history" style={{ color: '#ff8e05' }} title="Pending"></i>
            {' ' + val.pending_count}) / <span title="Total">{val.count}</span>
          </div>
        ) : (
          <div>-</div>
        ),
      // sorter: true,
      // filtro
    },
    {
      title: 'Type',
      render: (val) => {
        return JOB_TYPE_VALUES.find((o) => o.value === val.type)?.label ?? '';
      },
    },
  ];

  return (
    <ServerSideTable<JobImportWithCounts>
      search={true}
      columns={tableColumns}
      fetchApi={api.jobs.getImportsList}
      rowClickHandler={(row: JobImportWithCounts) => {
        navigate(urlUtils.jobs.imports.detail(row.id));
      }}
    />
  );
}

export default JobImportsTablePage;
